define('database-dashboard/routes/application', ['exports', 'database-dashboard/routes/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    title: function title(tokens) {
      var base = '';
      if (tokens && tokens.length > 0) {
        var joined = '' + tokens.reverse().join(' - ');
        if (base) {
          joined = joined + ' - ' + base;
        }
        return joined;
      } else {
        return base;
      }
    },


    actions: {
      addLayoutOffset: function addLayoutOffset() {
        this.controllerFor('application').set('layoutOffset', true);
      },
      error: function error(_error) {
        this.controllerFor('application').set('layoutOffset', false);

        var errorTemplate = _error.template || 'error';
        var parentTemplate = _error.parent || 'application';
        var controller = _error.controller || 'application';

        if (_error.controller) {
          this.controllerFor(controller).setProperties(this.modelFor(controller));
        }

        this.render();
        if (parentTemplate !== 'application') {
          this.render(parentTemplate, { into: 'application' });
        }
        this.render(errorTemplate, { into: parentTemplate });
      }
    }
  });
});