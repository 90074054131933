define('database-dashboard/components/button-group', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    content: null,
    selected: null,
    action: null,
    actions: {
      click: function click(position) {
        this.set("selected", position);
        return this.get('action')(position);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super();
      if (Ember.isEmpty(this.get("selected"))) {
        return this.set("selected", this.get("content")[0].value);
      }
    }
  });
});