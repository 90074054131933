define('database-dashboard/components/database-upgrade', ['exports', 'database-dashboard/models/operation'], function (exports, _operation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    confirmationModal: Ember.inject.service('confirmation-modal'),
    // Injected
    db: null,
    store: null,

    // Internals
    isUpgrading: false,
    hasError: false,

    upgradeMessage: null,
    loadingOperation: true,

    // Computed
    isDisabled: Ember.computed('isUpgrading', 'hasError', 'db.isTimescaleDB', function () {
      return this.get('isUpgrading') || this.get('hasError') || !this.get('isRunning') || this.get('db.isTimescaleDB');
    }),

    isRunning: Ember.computed('db.status', function () {
      return this.get('db.status') == 'running';
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.get('db.currentOperation').then(function () {
        _this.set('loadingOperation', false);
      });

      if (!this.get('isRunning')) {
        this.set('upgradeMessage', 'The database status must be "running" to be able to run an upgrade. It is currently "' + this.get('db.status') + '".');
      }
    },

    // Observer
    databaseStatusObserver: Ember.observer('db.status', function () {
      if (!this.get('isRunning')) {
        this.set('upgradeMessage', 'The database status must be "running" to be able to run an upgrade. It is currently "' + this.get('db.status') + '".');
      }
    }),

    currentOperationObserver: Ember.observer('db.currentOperation', 'db.currentOperation.status', function () {
      var _this2 = this;

      this.get('db.currentOperation').then(function (op) {
        // if there is no currentOperation related to this DB
        if (op === null) {
          return;
        }
        var status = op.get('status');
        // If the controller is not currently upgrading but the operation have a status
        // meaning upgrading
        if (!_this2.get('isUpgrading') && op.get('isUpgrading')) {
          _this2.set('isUpgrading', true);
          var adapter = _this2.get('store').adapterFor('operation');
          var url = adapter.buildURL('operation', op.get('id'));
          var getOperationPromise = _this2.get('db').monitorOperation(url);
          _this2.handleGetOperationPromise(getOperationPromise);
        }
        var msg = null;
        if (op.get('type') == _operation.types.UPGRADE) {
          switch (status) {
            case _operation.statuses.PENDING:
              msg = 'Database upgrade is pending';
              break;
            case _operation.statuses.RUNNING:
              msg = 'Database upgrade is running';
              break;
            case _operation.statuses.FINISHED:
              msg = 'Database upgrade finished successfully';
              break;
            case _operation.statuses.USER_ERROR:
              msg = op.get('error');
              break;
            case _operation.statuses.ERROR:
              _this2.set('hasError', true);
              msg = op.get('error');
              if (Ember.isEmpty(msg)) {
                msg = 'An error occurred during the upgrade process. Please contact the support for further assistance.';
              }
              break;
          }
        } else {
          msg = "Another operation is in progress on the database";
        }
        _this2.set('upgradeMessage', msg);
      });
    }),

    // Methods
    handleGetOperationPromise: function handleGetOperationPromise(promise) {
      var _this3 = this;

      promise.then(function () {
        // Upgrade finished successfully
        _this3.get('db').reload();
      }).finally(function () {
        _this3.set('isUpgrading', false);
      });
    },


    actions: {
      upgradeDatabase: function upgradeDatabase() {
        var _this4 = this;

        this.set('isUpgrading', true);

        var _get$upgrade = this.get('db').upgrade(),
            triggered = _get$upgrade.triggered,
            done = _get$upgrade.done;

        triggered.then(function () {
          // If success requesting the upgrade of the DB
        }, function () {
          // If error requesting the DB upgrade
          _this4.set('upgradeMessage', 'An error occurred during the upgrade process. Our team has been notified. Please contact the support for further assistance.');
        });

        this.handleGetOperationPromise(done);
      },
      toggleUpgradeDatabaseConfirmation: function toggleUpgradeDatabaseConfirmation() {
        var _this5 = this;

        var versionString = this.get('db.nextVersion.toFullVersionString');
        this.get('confirmationModal').display({
          okLabel: 'Upgrade',
          action: function action() {
            _this5.send("upgradeDatabase");
          },
          prompt: 'Do you really want to upgrade to ' + versionString + '?'
        });
      }
    }
  });
});