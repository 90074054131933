define('database-dashboard/routes/dashboard/databases', ['exports', 'database-dashboard/mixins/retry-until-success'], function (exports, _retryUntilSuccess) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_retryUntilSuccess.default, {
    titleToken: 'Databases',

    model: function model() {
      return Ember.RSVP.hash({
        db: this.modelFor("dashboard")["db"]
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties(model);
    },


    actions: {
      didTransition: function didTransition() {
        this.send('updateSelectedTab', 'databases');
      }
    }
  });
});