define('database-dashboard/adapters/database-user', ['exports', 'database-dashboard/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var UserAdapter = _application.default.makeNestedResourceAdapter({
    parent: {
      path: 'databases',
      property: 'dbId'
    },
    resource: {
      path: 'users',
      property: 'userId'
    }
  });

  exports.default = UserAdapter;
});