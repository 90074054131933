define('database-dashboard/components/logs-archive', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Injected
    db: null,

    noMoreAvailable: false,
    loading: false,
    cursor: "",
    archives: [],
    isEmpty: true,

    didInsertElement: function didInsertElement() {
      this.fetch();
    },
    fetch: function fetch() {
      var _this = this;

      var archivesAjax, archivesPromise, archivesUrl;
      this.set('loading', true);
      archivesUrl = window.EmberENV.apiBaseURI + "/databases/" + this.get("db.id") + "/logs_archives?cursor=" + this.cursor;
      archivesAjax = Ember.$.ajax({
        url: archivesUrl
      });
      archivesPromise = new Ember.RSVP.Promise(function (resolve, reject) {
        return archivesAjax.then(resolve, reject);
      });
      return archivesPromise.then(function (value) {
        _this.set('cursor', value.next_cursor);
        _this.set('archives', _this.get('archives').concat(value.archives));
        _this.set('noMoreAvailable', !value.has_more);
        _this.set('loading', false);
        if (_this.get('archives').length > 0) {
          _this.set('isEmpty', false);
        }
      });
    },


    actions: {
      viewMore: function viewMore() {
        return this.fetch();
      }
    }
  });
});