define('database-dashboard/utils/flatten-query-params', ['exports', 'ember-data-url-templates/utils/flatten-query-params'], function (exports, _flattenQueryParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _flattenQueryParams.default;
    }
  });
});