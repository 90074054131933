define('database-dashboard/components/advanced-mysql', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AdvancedMySQLComponent = Ember.Component.extend({
    // Injected
    db: null,
    sqlModes: [],
    isLoading: true,
    isSubmitting: false,
    error: null,
    allSQLModes: ["ALLOW_INVALID_DATES", "ANSI", "ANSI_QUOTES", "DB2", "ERROR_FOR_DIVISION_BY_ZERO", "HIGH_NOT_PRECEDENCE", "IGNORE_SPACE", "MAXDB", "MSSQL", "MYSQL323", "MYSQL40", "NO_AUTO_CREATE_USER", "NO_AUTO_VALUE_ON_ZERO", "NO_BACKSLASH_ESCAPES", "NO_DIR_IN_CREATE", "NO_ENGINE_SUBSTITUTION", "NO_FIELD_OPTIONS", "NO_KEY_OPTIONS", "NO_TABLE_OPTIONS", "NO_UNSIGNED_SUBTRACTION", "NO_ZERO_DATE", "NO_ZERO_IN_DATE", "ONLY_FULL_GROUP_BY", "ORACLE", "PAD_CHAR_TO_FULL_LENGTH", "PIPES_AS_CONCAT", "POSTGRESQL", "REAL_AS_FLOAT", "STRICT_ALL_TABLES", "STRICT_TRANS_TABLES", "TRADITIONAL"],

    didInsertElement: function didInsertElement() {
      this.loadSQLMode();
    },
    loadSQLMode: function loadSQLMode() {
      var _this = this;

      this.get('db').sqlMode().then(function (sqlMode) {
        if (sqlMode === "") {
          _this.set('sqlModes', []);
        } else {
          _this.set('sqlModes', sqlMode.split(','));
        }
      }).finally(function () {
        _this.set('isLoading', false);
        _this.set("isSubmitting", false);
      });
    },


    areInputsDisabled: Ember.computed('isSubmitting', 'canUpdateConfig', function () {
      return this.get('isSubmitting') || !this.get('db.version.canUpdateConfig');
    }),

    actions: {
      setSQLMode: function setSQLMode() {
        var _this2 = this;

        this.set("isSubmitting", true);
        this.set("error", null);
        var mode = this.get('sqlModes').join(',');
        this.get('db').setSQLMode(mode).catch(function (e) {
          _this2.set('error', e.error);
        }).finally(function () {
          _this2.loadSQLMode();
        });
      }
    }
  });

  exports.default = AdvancedMySQLComponent;
});