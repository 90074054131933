define('database-dashboard/helpers/database-types', ['exports', 'database-dashboard/models/database'], function (exports, _database) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var databaseTypes = function databaseTypes(dbType) {
    return _database.dbTypes[dbType];
  };

  exports.default = Ember.Helper.helper(databaseTypes);
});