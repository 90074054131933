define('database-dashboard/models/database-type-version', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.versionFeatures = undefined;
  var versionFeatures = exports.versionFeatures = {
    TLS: "tls",
    MySQLNoCustomConfig: "mysql-no-custom-config"
  };

  exports.default = _emberData.default.Model.extend({
    createdAt: _emberData.default.attr('date'),
    databaseTypeId: _emberData.default.attr('string'),
    major: _emberData.default.attr('number'),
    minor: _emberData.default.attr('number'),
    patch: _emberData.default.attr('number'),
    build: _emberData.default.attr('number'),
    updatedAt: _emberData.default.attr('date'),
    features: _emberData.default.attr(),

    hasFeature: function hasFeature(feature) {
      if (!feature) {
        return false;
      }
      var features = this.get('features');
      if (!features) {
        return false;
      }

      return features.contains(feature);
    },


    // Added relationships
    databaseType: _emberData.default.belongsTo('databaseType'),
    databases: _emberData.default.hasMany('database', { inverse: "version" }),
    allowedPlugins: _emberData.default.hasMany('databaseFeatures', { inverse: "dbTypeVersion" }),

    hasTLS: Ember.computed('features.[]', function () {
      return this.hasFeature(versionFeatures.TLS);
    }),

    canUpdateConfig: Ember.computed('features.[]', function () {
      return !this.hasFeature(versionFeatures.MySQLNoCustomConfig);
    }),

    toVersionString: Ember.computed('major', 'minor', 'patch', 'build', function () {
      return this.get('major') + '.' + this.get('minor') + '.' + this.get('patch');
    }),

    toFullVersionString: Ember.computed('major', 'minor', 'patch', 'build', function () {
      return this.get('major') + '.' + this.get('minor') + '.' + this.get('patch') + '-' + this.get('build');
    }),

    isUnderV3: Ember.computed('major', function () {
      return this.get('major') < 3;
    })
  });
});