define("database-dashboard/routes/index", ["exports", "database-dashboard/routes/authenticated"], function (exports, _authenticated) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticated.default.extend({
    beforeModel: function beforeModel(transition) {
      this._super(arguments);

      if (transition.targetName === "index.index") {
        this.transitionTo("you-are-lost");
      }
    },
    renderTemplate: function renderTemplate() {
      this.render('components/nav-drawer-layout', {
        outlet: 'nav',
        into: 'application'
      });
      this.render();
    },


    actions: {
      updateNavTitle: function updateNavTitle(title) {
        this.controllerFor('index').set('navTitle', title);
      }
    }
  });
});