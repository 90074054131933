define('database-dashboard/components/datetime-picker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    timezone: moment.tz.guess(),
    timezones: moment.tz.names(),
    curMinute: "00",
    curHour: "00",
    curDate: Ember.computed(function () {
      return moment().format("L");
    }),
    dates: Ember.computed(function () {
      return Array(7).fill().map(function (_, i) {
        return moment().subtract(i, 'days').format("L");
      });
    }),
    hours: Ember.computed(function () {
      var res = [];
      for (var i = 0; i < 24; i++) {
        if (i < 10) {
          res.push('0' + i);
        } else {
          res.push('' + i);
        }
      }
      return res;
    }),
    minutes: Ember.computed(function () {
      var res = [];
      for (var i = 0; i < 60; i++) {
        if (i < 10) {
          res.push('0' + i);
        } else {
          res.push('' + i);
        }
      }
      return res;
    }),

    didInsertElement: function didInsertElement() {
      this.sendAction('onChange', this.formatedDate());
    },
    formatedDate: function formatedDate() {
      var zoneFormat = moment.tz(this.get('timezone')).format('Z');
      var curDateFormat = moment(this.get('curDate')).format('YYYY-MM-DD');
      return curDateFormat + 'T' + this.get('curHour') + ':' + this.get('curMinute') + ':00' + zoneFormat;
    },


    onPropertyChange: Ember.observer('timezone', 'curMinute', 'curHour', 'curDate', function () {
      this.sendAction('onChange', this.formatedDate());
    })
  });
});