define("database-dashboard/components/single-disk-chart", ["exports", "database-dashboard/components/single-chart"], function (exports, _singleChart) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _singleChart.default.extend({
    databasePlan: null,

    // values contains the JSON returned by the DB API call
    // types contains what is defined in diskUsedTypes in db-disk-metrics
    formatValues: function formatValues(values, type) {
      // We want to use the `disk_used` key in the disk metrics
      var formattedValues = values["disk_metrics"].flatMap(function (val) {
        return { time: val["time"], value: val["disk_used"] };
      });
      return this._super(formattedValues, type);
    },

    formatAxis: function formatAxis(axis, max) {
      var planDiskBytes = this.databasePlan.get("diskBytes");
      // The y-axis max is the amount included in the plan, or the max usage + 20% if the database uses more than what is included in the plan
      if (planDiskBytes > max) {
        axis.y.max = planDiskBytes;
      } else {
        // The max y-axis value is the max + 20%
        axis.y.max = max + max * 0.2;
      }
      axis.y.min = 0;
      return axis;
    }
  });
});