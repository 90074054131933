define('database-dashboard/components/sc-md-input', ['exports', 'database-dashboard/components/md-input', 'database-dashboard/templates/components/sc-md-input'], function (exports, _mdInput, _scMdInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // This component add Material Design input hint logic
  var ScMdInputComponent = _mdInput.default.extend({
    layout: _scMdInput.default,

    hint: null,
    suffix: null
  });

  exports.default = ScMdInputComponent;
});