define("database-dashboard/initializers/helpers", ["exports", "database-dashboard/helpers/date"], function (exports, _date) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var HelpersInitializer = {
    name: "helpers",
    initialize: function initialize(app) {
      new _date.default(app);
    }
  };

  exports.default = HelpersInitializer;
});