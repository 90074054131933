define('database-dashboard/initializers/confirmation-modal', ['exports', 'database-dashboard/services/confirmation-modal'], function (exports, _confirmationModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ConfirmationModalInitializer = {
    name: 'confirmation-modal',
    initialize: function initialize(application) {

      var modalService = _confirmationModal.default.create();

      application.register('confirmation-modal:current', modalService, { instantiate: false, singleton: true });

      application.inject('component', 'confirmation-modal', 'confirmation-modal:current');
      application.inject('controller', 'confirmation-modal', 'confirmation-modal:current');
      application.inject('route', 'confirmation-modal', 'confirmation-modal:current');
    }
  };

  exports.default = ConfirmationModalInitializer;
});