define('database-dashboard/adapters/application', ['exports', 'ember-data', 'active-model-adapter'], function (exports, _emberData, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // http://emberjs.com/guides/models/#toc_store
  // http://emberjs.com/guides/models/pushing-records-into-the-store/
  var ApplicationAdapter = _activeModelAdapter.default.extend({
    namespace: '',
    host: Ember.computed(function () {
      var host = window.EmberENV.apiBaseURI;
      // if (host.charAt(host.length - 1) != '/') {
      // host += "/"
      // }
      return host;
    }),

    // ajaxError has been changed to handleResponse
    // https://github.com/ember-data/active-model-adapter/commit/fae3159deedb5a02a1d198041134e802cdf2b5b1
    handleResponse: function handleResponse(status, headers, payload) {
      if (this.isInvalid(status, headers, payload)) {
        var payloadErrors = payload.errors;
        var errors = {};

        if (payloadErrors != null) {
          Object.keys(payloadErrors).forEach(function (key) {
            errors[Ember.String.camelize(key)] = payloadErrors[key];
          });
        }

        // include global error
        if (payload.error) {
          errors.error = payload.error;
        }

        // Here we use DS.Error instead of DS.InvalidError
        // because InvalidError awaits a json-api formatted array.
        // Now, the errors are directly available on the .save()'s fail handler
        return new _emberData.default.Errors(errors);
      } else {
        return this._super.apply(this, arguments);
      }
    }

  });

  ApplicationAdapter.reopenClass({
    makeNestedResourceAdapter: function makeNestedResourceAdapter(_ref) {
      var parent = _ref.parent,
          resource = _ref.resource;

      var argumentsPresent = parent.path != null && parent.property != null && resource.path && resource.property;
      (true && !(argumentsPresent) && Ember.assert("Resource adapter factory requires all arguments to be present", argumentsPresent));


      return this.extend({
        parent: parent,
        resource: resource,

        buildNestedURL: function buildNestedURL(type, parentId, resourceId, endpoint, record) {
          if (Ember.isPresent(parentId)) {
            var base = '' + this.get("host") + this.get('namespace') + '/' + parent.path + '/' + parentId;

            if (Ember.isPresent(endpoint)) {
              return base + '/' + endpoint.trim('/');
            }

            if (Ember.isPresent(resourceId)) {
              base = base + '/' + resource.path + '/' + resourceId.toString();
            } else {
              base = base + '/' + resource.path;
            }
            return base;
          } else {
            return this.buildURL(type.typeKey, resourceId, record);
          }
        },
        findRecord: function findRecord(store, type, id, snapshot) {
          try {
            var parentId = snapshot.record.get(parent.property);
            var url = this.buildNestedURL(type, parentId, id, null, snapshot.record);
            return this.ajax(url, 'GET');
          } catch (e) {
            return this._super.apply(this, arguments);
          }
        },
        queryRecord: function queryRecord(store, type, query) {
          try {
            var parentQuery = query[parent.property];
            var resourceQuery = query[resource.property];
            var endpointQuery = query.endpoint;

            var url = this.buildNestedURL(type, parentQuery, resourceQuery, endpointQuery);
            return this.ajax(url, 'GET');
          } catch (e) {
            return this._super.apply(this, arguments);
          }
        },
        findAll: function findAll() {
          return this._super.apply(this, arguments);
        },
        query: function query(store, type, _query) {
          try {
            if (!Ember.isPresent(_query[parent.property])) {
              throw 'NestedResourceAdapter ' + type + ' is missing property ' + this.parent.property;
            }

            var parentQuery = _query[parent.property];
            var resourceQuery = _query[resource.property];
            var endpointQuery = _query.endpoint;

            var url = this.buildNestedURL(type, parentQuery, resourceQuery, endpointQuery);

            delete _query[parent.property];
            delete _query[resource.property];
            delete _query.endpoint;

            return this.ajax(url, 'GET', { data: _query });
          } catch (e) {
            return this._super.apply(this, arguments);
          }
        },
        createRecord: function createRecord(store, type, snapshot) {
          var parentId = snapshot.record.get(this.parent.property);

          var data = {};
          var serializer = store.serializerFor(type.modelName);
          serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

          var url = this.buildNestedURL(type, parentId, null, null, snapshot.record);
          return this.ajax(url, 'POST', { data: data });
        },
        deleteRecord: function deleteRecord(store, type, snapshot) {
          var serializer = store.serializerFor(type.modelName);
          var parentId = snapshot.record.get(parent.property);
          var resourceId = snapshot.record.get(serializer.primaryKey);
          var url = this.buildNestedURL(type, parentId, resourceId, null, snapshot.record);

          return this.ajax(url, 'DELETE');
        }
      });
    }
  });

  exports.default = ApplicationAdapter;
});