define('database-dashboard/models/database-feature', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    displayName: _emberData.default.attr('string'),
    featureName: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    installed: _emberData.default.attr('boolean'),

    // Added relationships
    dbTypeVersion: _emberData.default.belongsTo('databaseTypeVersion', { inverse: "allowedPlugins" })
  });
});