define('database-dashboard/components/advanced-influxdb', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AdvancedInfluxdbComponent = Ember.Component.extend({
    confirmationModal: Ember.inject.service('confirmation-modal'),
    // Injected
    db: null,

    // Internal
    isAdding: null,
    policyToAdd: {},
    influxRetentionPolicies: Ember.A(),
    policiesSorting: ['name:asc'],
    sortedInfluxRetentionPolicies: Ember.computed.sort('influxRetentionPolicies', 'policiesSorting'),

    isDeleting: null,
    policyToDelete: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.get('db').action("list-retention-policies").then(function (action) {
        _this.set('influxRetentionPolicies', action.result || Ember.A());
      }, function (res) {
        var errorMsg = 'Failed to add the retention policy \'' + _this.get('policy.name') + '\'';
        if (res.error) {
          errorMsg += ' (' + res.error + ')';
        }
        _this.get('notifier').notify(errorMsg);
      });
    },


    actions: {
      addRetentionPolicy: function addRetentionPolicy() {
        var _this2 = this;

        this.set('isAdding', true);
        var policy = this.get('policyToAdd');
        this.get('db').action('add-retention-policy', policy).then(function () {
          _this2.get('notifier').notify("Retention policy '" + policy.name + "' successfully added");
          if (policy.default) {
            // Remove the default attribute from the old default RP
            _this2.get('influxRetentionPolicies').forEach(function (rp) {
              if (rp.default) {
                Ember.set(rp, "default", null);
              }
            });
          }
          _this2.get('influxRetentionPolicies').addObject(policy);
          _this2.set('policyToAdd', {});
        }, function (res) {
          var errorMsg = 'Failed to add the retention policy \'' + policy.name + '\'';
          if (res.error) {
            errorMsg += ' (' + res.error + ')';
          }
          _this2.get('notifier').notify(errorMsg);
        }).finally(function () {
          _this2.set('isAdding', null);
        });
      },
      deleteRetentionPolicy: function deleteRetentionPolicy() {
        var _this3 = this;

        this.set('isDeleting', true);
        var policy = this.get('policyToDelete');
        this.get('db').action('del-retention-policy', policy).then(function () {
          _this3.get('notifier').notify("Retention policy '" + policy.name + "' successfully deleted");
          _this3.get('influxRetentionPolicies').removeObject(policy);
        }, function (res) {
          var errorMsg = 'Failed to add the retention policy \'' + policy.name + '\'';
          if (res.error) {
            errorMsg += ' (' + res.error + ')';
          }
          _this3.get('notifier').notify(errorMsg);
        }).finally(function () {
          _this3.set('isDeleting', null);
          _this3.set('policyToDelete', null);
        });
      },
      toggleDeleteRetentionPolicyConfirmation: function toggleDeleteRetentionPolicyConfirmation(policy) {
        var _this4 = this;

        this.set('policyToDelete', policy);
        this.get('confirmationModal').display({
          okLabel: 'Delete',
          action: function action() {
            _this4.send('deleteRetentionPolicy');
          },
          prompt: 'Do you really want to delete the retention policy \'' + policy.name + '\'?'
        });
      }
    }
  });

  exports.default = AdvancedInfluxdbComponent;
});