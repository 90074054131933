define('database-dashboard/components/sc-md-btn', ['exports', 'database-dashboard/components/md-btn', 'database-dashboard/templates/components/sc-md-btn'], function (exports, _mdBtn, _scMdBtn) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ScMdBtnComponent = _mdBtn.default.extend({
    layout: _scMdBtn.default,
    colorClass: null,
    isLoading: false,

    classNameBindings: ['colorClass', 'useDefaultColor:btn-color', 'deleteClass', 'isLoading:btn-loading'],

    wavesClass: Ember.computed('isFlat', function () {
      if (this.get('isFlat')) {
        return 'waves-dark';
      } else {
        return 'waves-light';
      }
    }),

    useDefaultColor: Ember.computed('isFlat', 'text', 'isDisabled', 'isDeleteBtn', 'colorClass', function () {
      if (this.get('isDisabled')) {
        return false;
      }
      if (this.get('colorClass')) {
        return false;
      }
      if (this.get('isDeleteBtn')) {
        return false;
      }

      if (this.get('isFlat')) {
        var text = '';
        if (this.get('text')) {
          text = this.get('text').toLowerCase();
        }
        return text !== 'cancel' && text !== 'close' && text !== 'dismiss';
      } else {
        return true;
      }
    }),

    isDeleteBtn: Ember.computed('icon', 'text', function () {
      var text = this.get('text');
      var icon = this.get('icon');
      if (text) {
        text = text.toLowerCase();
      }
      return text === 'delete' || icon && icon.indexOf('delete') != -1;
    }),

    deleteClass: Ember.computed('isDeleteBtn', 'isFlat', function () {
      if (this.get('isDeleteBtn')) {
        if (this.get('isFlat')) {
          return 'materialize-red-text';
        } else {
          return 'materialize-red';
        }
      }
      return null;
    }),

    // Remove this when https://github.com/mike-north/ember-cli-materialize/issues/201
    // is finally fixed
    click: function click() {
      if (!this.get('isDisabled') && this.get('action')) {
        this.get('action')(this.get('actionArg'));
      }
    }
  });

  exports.default = ScMdBtnComponent;
});