define('database-dashboard/initializers/session', ['exports', 'database-dashboard/models/session'], function (exports, _session) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SessionInitializer = {
    name: 'session-data',
    initialize: function initialize(application) {

      // Make current session available everywhere
      var session = _session.default.create();

      application.register('session:current', session, { instantiate: false, singleton: true });

      application.inject('controller', 'session', 'session:current');
      application.inject('route', 'session', 'session:current');
    }
  };

  exports.default = SessionInitializer;
});