define('database-dashboard/controllers/dashboard/pg-query-statistics', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // injected
    db: Ember.computed.alias('model.db'),

    // HTML
    classNames: ['row'],

    statistics: null,
    isEnabling: null,
    isReloading: null,
    isReseting: null,

    actions: {
      enable: function enable() {
        var _this = this;

        this.set('isEnabling', true);
        this.get('db').action('pg-stat-statements-enable').then(function () {
          _this.get('notifier').notify("PostgreSQL pg-stat-statements has been enabled");
          var promise = _this.get('db').refreshExtensionsList();
          promise.then(function () {
            _this.send('reload', { dontNotify: true });
          });
        }, function () {
          _this.get('notifier').notify("Failed to enable 'pg-stat-statements' extension, our team has been notified");
        }).finally(function () {
          _this.set('isEnabling', null);
        });
      },
      reset: function reset() {
        var _this2 = this;

        this.set('isReseting', true);
        this.get('db').action('pg-stat-statements-reset').then(function () {
          _this2.set('statistics', null);
          _this2.get('notifier').notify("PostgreSQL stats have been reseted");
        }, function () {
          _this2.get('notifier').notify("Failed to reset stats, our team has been notified");
        }).finally(function () {
          _this2.set('isReseting', null);
        });
      },
      reload: function reload(opts) {
        var _this3 = this;

        opts = opts || {};
        var shouldNotify = opts.dontNotify !== true;

        this.set('isReloading', true);
        this.get('db').action('pg-stat-statements-list').then(function (action) {
          _this3.set('statistics', action.result);
          if (shouldNotify) {
            _this3.get('notifier').notify("PostgreSQL stats have been refreshed");
          }
        }, function () {
          _this3.get('notifier').notify("Failed to fetch stats, our team has been notified");
        }).finally(function () {
          _this3.set('isReloading', null);
        });
      },
      selectionChanged: function selectionChanged(query) {
        this.set('selectedQuery', query);
      }
    }
  });
});