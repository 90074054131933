define('database-dashboard/helpers/upper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.truncate = truncate;
  function truncate(params) {
    var value = params[0];
    return value.toUpperCase();
  }

  exports.default = Ember.Helper.helper(truncate);
});