define('database-dashboard/components/user-management', ['exports', 'database-dashboard/models/database'], function (exports, _database) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var UserManagementComponent = Ember.Component.extend({
    confirmationModal: Ember.inject.service('confirmation-modal'),
    // Injected
    db: null,
    store: null,
    users: null,

    // Internal
    errors: null,
    user: {},
    isCreating: null,
    isDeleting: null,

    hasNoUser: Ember.computed('users', function () {
      return !this.get('users') || this.get('users.length') === 0;
    }),

    isMongoDb: Ember.computed('db', function () {
      if (!this.get('db')) {
        return false;
      }
      return this.get('db').get('typeName') === _database.dbTypes.MONGO;
    }),

    getMongoConnectionString: Ember.computed('db', function () {
      if (!this.get('db')) {
        return false;
      }
      var hostname = this.get('db').get('hostname');
      var resourceId = this.get('db').get('resourceId');

      return "mongodb://<user>:<password>@" + hostname + "/<db_name>?authSource=" + resourceId;
    }),

    actions: {
      newUser: function newUser() {
        var _this = this;

        var db = this.get('db');
        // inject nested resource attributes
        var userRecord = this.get('store').createRecord('database-user', {
          dbId: db.id,
          database: db
        });
        userRecord.setProperties(this.get('user'));

        this.set('isCreating', true);
        userRecord.save().then(function (user) {
          _this.get('notifier').notify('User created: ' + user.get('name'));

          // Ember 1.13 hack https://github.com/emberjs/data/issues/3313
          _this.get('users').pushObject(user._internalModel);

          // Show API generated password
          var password = user.get('password');
          if (password.replace(/\*/g, '')) {
            _this.get('notifier').notify('Please save the generated password: ' + user.get('password') + ' <a class="do-copy" style="cursor: pointer; margin-left: 8px;" data-clipboard-text="' + user.get('password') + '">COPY</a>', 'LONG');
          }

          _this.setProperties({
            errors: null,
            user: {}
          });
        }, function (errors) {
          if (errors.error) {
            _this.get('notifier').notify(errors.error);
          }
          _this.set('errors', errors);
        }).finally(function () {
          _this.set('isCreating', null);
          if (userRecord.get('isNew')) {
            userRecord.unloadRecord();
          }
        });
      },
      deleteUser: function deleteUser(u) {
        var _this2 = this;

        this.set('isDeleting', true);

        u.set('dbId', u.get('database.id'));
        u.destroyRecord().then(function () {
          _this2.get('users').removeObject(u);
          _this2.get('notifier').notify('User deleted: ' + u.get('name'));
        }, function (errors) {
          _this2.get('notifier').notify('Can not delete user: ' + u.get('name'));
          _this2.set('errors', errors);
        }).finally(function () {
          _this2.set('isDeleting', null);
        });
      },
      toggleDeleteUserConfirmation: function toggleDeleteUserConfirmation(u) {
        var _this3 = this;

        this.get('confirmationModal').display({
          okLabel: 'Delete',
          action: function action() {
            _this3.send('deleteUser', u);
          },
          prompt: 'Do you really want to delete the user ' + u.get('name') + '?'
        });
      }
    }
  });

  exports.default = UserManagementComponent;
});