define('database-dashboard/models/database-backup', ['exports', 'ember-data', 'database-dashboard/models/database'], function (exports, _emberData, _database) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.BackupStatuses = undefined;
  var BackupStatuses = exports.BackupStatuses = {
    SCHEDULED: 'scheduled',
    RUNNING: 'running',
    DONE: 'done',
    ERROR: 'error'
  };

  exports.default = _emberData.default.Model.extend({
    createdAt: _emberData.default.attr('date'),
    startedAt: _emberData.default.attr('date'),
    databaseId: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    method: _emberData.default.attr('string'),
    size: _emberData.default.attr('number'),
    status: _emberData.default.attr('string'),

    // Added relationships
    database: _emberData.default.belongsTo('database'),

    isDownloadable: Ember.computed('status', function () {
      return this.get('status') == BackupStatuses.DONE && this.get('database.typeName') != _database.dbTypes.ELASTICSEARCH;
    }),

    isRestorable: Ember.computed('status', function () {
      return this.get('status') == BackupStatuses.DONE && (this.get('database.typeName') == _database.dbTypes.REDIS || this.get('database.typeName') == _database.dbTypes.ELASTICSEARCH);
    }),

    downloadUrl: Ember.computed(function () {
      return window.EmberENV.apiBaseURI + '/databases/' + this.get('databaseId') + '/backups/' + this.get('id') + '/archive';
    }),

    restoreUrl: Ember.computed(function () {
      return window.EmberENV.apiBaseURI + '/databases/' + this.get('databaseId') + '/backups/' + this.get('id') + '/restore';
    }),

    shouldBeReloaded: Ember.computed('status', function () {
      return this.get('status') !== BackupStatuses.DONE && this.get('status') !== BackupStatuses.ERROR;
    }),

    restore: function restore() {
      return $.ajax({
        method: "POST",
        url: this.get('restoreUrl'),
        contentType: 'application/json',
        data: JSON.stringify({ database_id: this.get('database.id') })
      });
    }
  });
});