define('database-dashboard/controllers/sso', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['timestamp', 'token', 'id', 'user_uuid'],
    timestamp: null,
    token: null,
    id: null,
    user_uuid: null,

    ssoFailed: false,
    ssoStatus: Ember.computed('ssoStatus', function () {
      var _this = this;

      this.checkSSOParams().then(function (response) {
        _this.set("ssoStatus", "Authenticated, redirecting...");

        var token = response.authentication_token;
        _this.get('session').set('token', token);

        var id = _this.get('id');
        return _this.get('store').findRecord('database', id);
      }).then(function (record) {
        var dbId = record.get('id');
        _this.get('session').set('databaseId', dbId);
        _this.transitionToRoute('dashboard', dbId);
      }).catch(function (error) {
        _this.set("ssoFailed", true);
        _this.set("ssoStatus", 'Invalid parameters.');
        Ember.Logger.error(error);
      });
      return "Checking authentication...";
    }),

    checkSSOParams: function checkSSOParams() {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var id = _this2.get('id');
        var token = _this2.get('token');
        var time = _this2.get('timestamp');
        var user_uuid = _this2.get('user_uuid');

        if (time == null || token == null || id == null) {
          return reject('Invalid parameters.');
        }

        var url = window.EmberENV.apiBaseURI + '/sso?timestamp=' + time + '&token=' + token + "&id=" + id;
        if (user_uuid) {
          url += "&user_uuid=" + user_uuid;
        }

        return resolve(Ember.$.ajax({
          dataType: 'json',
          contentType: 'application/json; charset=utf-8',
          url: url
        }));
      });
    }

  });
});