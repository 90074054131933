define('database-dashboard/components/database-access', ['exports', 'database-dashboard/models/database'], function (exports, _database) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    confirmationModal: Ember.inject.service('confirmation-modal'),

    // Injected
    db: null,

    // Internal
    errors: null,
    isTogglingInternetAccess: false,
    isTogglingForceSSL: false,

    hasForceSSL: Ember.computed.oneWay('db.hasForceSSL'),
    hasInternetAccess: Ember.computed.oneWay('db.hasInternetAccess'),

    isAccessLoading: Ember.computed('isTogglingInternetAccess', 'isInternetAccessLoading', 'isTogglingForceSSL', 'isForceSSLLoading', function () {
      return this.get('isTogglingInternetAccess') || this.get('isInternetAccessLoading') || this.get('isTogglingForceSSL') || this.get('isForceSSLLoading');
    }),

    isInternetCheckboxDisabled: Ember.computed('db.hasForceSSL', 'internetAccessError', 'isAccessLoading', function () {
      return !this.get('db.hasForceSSL') || this.get('internetAccessError') != "" || this.get('isAccessLoading');
    }),

    isForceSSLCheckboxDisabled: Ember.computed('db.hasInternetAccess', 'isAccessLoading', function () {
      return this.get('db.hasInternetAccess') || this.get('isAccessLoading');
    }),

    publiclyAvailableStatus: Ember.computed('db.features.[]', function () {
      return this.get('db').featureStatus(_database.features.PUBLICLY_AVAILABLE);
    }),

    isInternetAccessLoading: Ember.computed('publiclyAvailableStatus', function () {
      return this.get('publiclyAvailableStatus').startsWith(_database.featurestatus.PENDING);
    }),

    internetAccessError: Ember.computed('publiclyAvailableStatus', function () {
      if (this.get('publiclyAvailableStatus') == _database.featurestatus.FAILED) {
        return "An error occurred when applying this feature. Our team has been notified. Please contact the support for further assistance.";
      } else {
        return "";
      }
    }),

    forceSSLStatus: Ember.computed('db.features.[]', function () {
      return this.get('db').featureStatus(_database.features.FORCE_SSL);
    }),

    isForceSSLLoading: Ember.computed('forceSSLStatus', function () {
      return this.get('forceSSLStatus').startsWith(_database.featurestatus.PENDING);
    }),

    forceSSLError: Ember.computed('forceSSLStatus', function () {
      if (this.get('forceSSLStatus') == _database.featurestatus.FAILED) {
        return "An error occurred when applying this feature. Our team has been notified. Please contact the support for further assistance.";
      } else {
        return "";
      }
    }),

    actions: {
      toggleActivateInternetAccess: function toggleActivateInternetAccess(checker) {
        if (checker.checked) {
          this.send('enableFeature', {
            feature: _database.features.PUBLICLY_AVAILABLE,
            notificationMsg: 'Your database will be accessible from anywhere in an instant',
            toggleProperty: 'isTogglingInternetAccess',
            boundProperty: 'hasInternetAccess'
          });
        } else {
          this.send('disableFeature', {
            feature: _database.features.PUBLICLY_AVAILABLE,
            notificationMsg: 'Your database is now only accessible from our internal network',
            toggleProperty: 'isTogglingInternetAccess',
            boundProperty: 'hasInternetAccess'
          });
        }
      },
      toggleActivateForceSSL: function toggleActivateForceSSL(checker) {
        var _this = this;

        if (checker.checked) {
          this.send('enableFeature', {
            feature: _database.features.FORCE_SSL,
            notificationMsg: "Your database is now only accessible using encrypted connections",
            toggleProperty: 'isTogglingForceSSL',
            boundProperty: 'hasForceSSL'
          });
        } else {
          this.set('isTogglingForceSSL', true);
          this.get('confirmationModal').display({
            okLabel: 'I understand',
            action: function action() {
              _this.send('disableForceSSL');
            },
            prompt: 'While disabling Force TLS, your database will be restarted, a downtime might be noticed',
            extraIcon: 'mdi-alert-warning',
            cancel: function cancel() {
              _this.send('cancelForceSSLDeactivation');
            }
          });
        }
      },
      disableForceSSL: function disableForceSSL() {
        this.send('disableFeature', {
          feature: _database.features.FORCE_SSL,
          notificationMsg: 'Your database is now accessible using TLS/SSL or unencrypted',
          toggleProperty: 'isTogglingForceSSL',
          boundProperty: 'hasForceSSL'
        });
      },
      cancelForceSSLDeactivation: function cancelForceSSLDeactivation() {
        this.set('isTogglingForceSSL', false);
        this.set('hasForceSSL', true);
      },
      enableFeature: function enableFeature(opts) {
        var _this2 = this;

        this.set(opts.toggleProperty, true);
        this.get('db').addFeature(opts.feature).then(function () {
          _this2.get('notifier').notify(opts.notificationMsg);
          _this2.get('db').reloadUntilFeatureIsProvisionned(opts.feature, 1000);
        }, function (responseJSON) {
          _this2.set(opts.boundProperty, !_this2.get(opts.boundProperty));
          if (responseJSON.error) {
            _this2.get('notifier').notify(responseJSON.error);
          } else {
            _this2.get('notifier').notify("something went wrong when enabling '" + opts.feature + "'");
          }
        }).finally(function () {
          _this2.set(opts.toggleProperty, false);
        });
      },
      disableFeature: function disableFeature(opts) {
        var _this3 = this;

        this.set(opts.toggleProperty, true);
        this.get('db').removeFeature(opts.feature).then(function () {
          _this3.get('notifier').notify(opts.notificationMsg);
        }, function (responseJSON) {
          _this3.set(opts.boundProperty, !_this3.get(opts.boundProperty));
          if (responseJSON.error) {
            _this3.get('notifier').notify(responseJSON.error);
          } else {
            _this3.get('notifier').notify("something went wrong when disabling '" + opts.feature + "'");
          }
        }).finally(function () {
          _this3.set(opts.toggleProperty, null);
        });
      }
    }
  });
});