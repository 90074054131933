define('database-dashboard/components/database-logs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Injected
    db: null,

    // HTML
    tagName: 'pre',
    classNames: ['database-logs'],

    // Internal
    loggingInitialized: false,
    logsPreload: 500,
    logs: [],
    logsContent: Ember.computed('logs.[]', function () {
      var _this = this;

      // After the pre/code view has been updated, scroll to the bottom
      Ember.run.scheduleOnce('afterRender', function () {
        _this.element.scrollTop = 999999;
        _this.element.children[0].scrollTop = 999999;
      });
      return Ember.$("<div/>").text(this.get('logs').join('\n')).html();
    }),

    didInsertElement: function didInsertElement() {
      var _this2 = this;

      if (this.get('loggingInitialized')) {
        return;
      }
      this.set('loggingInitialized', true);
      this.get('db').logsURL().then(function (res) {
        var request = Ember.$.ajax({ method: "GET", url: res.url + "&n=" + _this2.get('logsPreload'), dataType: 'text' });
        request.done(function (data) {
          if (data) {
            var logs = _this2.get('logs');
            logs.pushObjects(data.split("\n"));
          }

          // https -> wss | http -> ws
          var wsURL = res.url.replace("http", "ws") + "&stream=true";
          var ws = new WebSocket(wsURL);
          ws.onopen = function (open) {
            Ember.Logger.debug('Connected to:', open.target.url);
          };

          ws.onmessage = function (event) {
            var content = JSON.parse(event.data);
            if (content.event == "log") {
              var strippedLine = content.log.replace(/^\s+|\s+$/g, '');
              _this2.get('logs').pushObject(strippedLine);
            }
          };
        });
      });
    }
  });
});