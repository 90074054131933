define('database-dashboard/components/sc-md-navbar', ['exports', 'database-dashboard/templates/components/sc-md-navbar', 'database-dashboard/components/md-navbar'], function (exports, _scMdNavbar, _mdNavbar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ScMdNavbarComponent = _mdNavbar.default.extend({
    tagName: 'div',
    layout: _scMdNavbar.default,

    _setupNavbar: function _setupNavbar() {
      this.$('.tooltipped').tooltip();
    },


    appURL: Ember.computed('appName', function () {
      return window.EmberENV.appDashboardURL + '/apps/' + this.get('appName') + '/addons';
    })
  });

  exports.default = ScMdNavbarComponent;
});