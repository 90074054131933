define('database-dashboard/components/stats-table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var StatsTableComponent = Ember.Component.extend({
    tagName: "table",
    classNames: ["bordered"],

    // Injected
    dbMetrics: null,

    ramStats: Ember.computed('dbMetrics.memory', function () {
      var currentUsage = this.get('dbMetrics.memory.memory');
      var maxUsage = this.get('dbMetrics.memory.memory_max');
      var limit = this.get('dbMetrics.memory.memory_limit');

      var current = currentUsage / limit * 100;
      var max = maxUsage / limit * 100;
      var free = 100 - current;

      return {
        percent: {
          current: current.toFixed(0),
          max: max.toFixed(0),
          free: free.toFixed(0)
        },
        free: limit - currentUsage
      };
    }),

    swapStats: Ember.computed('dbMetrics.memory', function () {
      var currentUsage = this.get('dbMetrics.memory.swap');
      var maxUsage = this.get('dbMetrics.memory.swap_max');
      var limit = this.get('dbMetrics.memory.swap_limit');

      var current = currentUsage / limit * 100;
      var max = maxUsage / limit * 100;
      var free = 100 - current;

      return {
        percent: {
          current: current.toFixed(0),
          max: max.toFixed(0),
          free: free.toFixed(0)
        },
        free: limit - currentUsage
      };
    })
  });

  exports.default = StatsTableComponent;
});