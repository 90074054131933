define('database-dashboard/components/advanced-redis', ['exports', 'database-dashboard/models/database'], function (exports, _database) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AdvancedRedisComponent = Ember.Component.extend({
    // Injected
    db: null,

    hasRDB: Ember.computed('db', function () {
      return this.get('db').hasFeature(_database.features.REDIS_RDB);
    }),
    hasAOF: Ember.computed('db', function () {
      return this.get('db').hasFeature(_database.features.REDIS_AOF);
    }),
    hasCacheMode: Ember.computed('db', function () {
      return this.get('db').hasFeature(_database.features.REDIS_CACHE);
    }),

    actions: {
      toggleRDB: function toggleRDB() {
        return this.send('toggleFeature', _database.features.REDIS_RDB, 'RDB');
      },
      toggleAOF: function toggleAOF() {
        return this.send('toggleFeature', _database.features.REDIS_AOF, 'AOF');
      },
      toggleCacheMode: function toggleCacheMode() {
        return this.send('toggleFeature', _database.features.REDIS_CACHE, 'CacheMode');
      },
      toggleFeature: function toggleFeature(feature, propSuffix) {
        var _this = this;

        var promise = null;
        var errorProp = 'error' + propSuffix;
        var loadingProp = 'toggling' + propSuffix;

        this.set(errorProp, null);
        this.set(loadingProp, true);
        if (this.get('db').hasFeature(feature)) {
          promise = this.get('db').removeFeature(feature);
        } else {
          promise = this.get('db').addFeature(feature);
        }

        promise.then(function () {
          _this.get('db').reload();
        }, function (error) {
          _this.set(errorProp, error);
        }).finally(function () {
          _this.set(loadingProp, null);
        });
      }
    }
  });

  exports.default = AdvancedRedisComponent;
});