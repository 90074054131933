define('database-dashboard/components/advanced-elasticsearch-feature', ['exports', 'database-dashboard/models/database'], function (exports, _database) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AdvancedElasticsearchFeatureComponent = Ember.Component.extend({
    confirmationModal: Ember.inject.service('confirmation-modal'),
    // Injected
    db: null,

    // Internal
    currentFeature: null,

    featureStatus: Ember.computed('db.features.[]', function () {
      return this.get('db').featureStatus(this.get('currentFeature.featureName'));
    }),

    isFeatureActivating: Ember.computed('featureStatus', function () {
      return this.get('featureStatus') == _database.featurestatus.PENDING;
    }),

    featureActivationObserver: Ember.observer('featureStatus', function () {
      if ([_database.featurestatus.PENDING, _database.featurestatus.NOTFOUND].includes(this.get('featureStatus'))) {
        return;
      }
      if (this.get('featureStatus') == _database.featurestatus.FAILED) {
        this.get('notifier').notify('Failed to activate ' + this.get('currentFeature.displayName') + '. Our team has been notified. Please contact the support for further assistance.');
        this.send('resetFeature');
        return;
      }

      this.get('notifier').notify(this.get('currentFeature.displayName') + ' is activated');
    }),

    actions: {
      toggleActivatePluginConfirmation: function toggleActivatePluginConfirmation(feature) {
        var _this = this;

        this.set('currentFeature', feature);
        this.get('confirmationModal').display({
          okLabel: 'Activate',
          action: function action() {
            _this.send('activateFeature');
          },
          prompt: 'Do you really want to activate ' + feature.get('displayName') + '?',
          extraIcon: 'mdi-alert-warning',
          extraMsg: 'Your database will be restarted after the activation. After enabling a plugin, you CANNOT disable it.',
          cancel: function cancel() {
            _this.send('resetFeature');
          }
        });
      },

      resetFeature: function resetFeature() {
        this.set('currentFeature.installed', false);
      },

      activateFeature: function activateFeature() {
        var feature = this.get('currentFeature');
        this.get('db').addFeature(feature.get('featureName'));
        this.get('db').reloadUntilFeatureIsProvisionned(feature.get('featureName'), 1000);
      }
    }
  });

  exports.default = AdvancedElasticsearchFeatureComponent;
});