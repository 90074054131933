define('database-dashboard/adapters/operation', ['exports', 'database-dashboard/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OperationAdapter = _application.default.extend({});

  exports.default = OperationAdapter;
});