define('database-dashboard/helpers/capitalize', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var string = params[0];
    if (!string) {
      return '';
    }
    return string.capitalize();
  });
});