define('database-dashboard/mixins/retry-until-success', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    retryUntilSuccess: function retryUntilSuccess(name, promiseFunc) {
      return new Ember.RSVP.Promise(function (resolve) {
        promiseFunc().then(function (res) {
          resolve(res);
        }, function () {
          var intervalID = setInterval(function () {
            promiseFunc().then(function (res) {
              clearInterval(intervalID);
              resolve(res);
            }, function (error) {
              Ember.Logger.debug("promise '" + name + "' failed to resolve: " + error);
            });
          }, 10000);
        });
      });
    }
  });
});