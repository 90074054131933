define('database-dashboard/helpers/instance-size', ['exports', 'database-dashboard/models/instance'], function (exports, _instance) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (dbType, plan, instanceType) {
    var mb = 1024 * 1024;
    var gb = 1024 * mb;

    if (instanceType == _instance.instanceTypes.GATEWAY) {
      return 128 * mb;
    }

    var default_size = 512 * mb;
    var sizes = {
      'default': {
        'free': 512 * mb,
        '64m': 64 * mb,
        '64m-free': 64 * mb,
        '128m': 128 * mb,
        '128m-free': 128 * mb,
        '256m': 256 * mb,
        '512m': 512 * mb,
        '1g': gb,
        '2g': 2 * gb,
        '4g': 4 * gb,
        '8g': 8 * gb,
        '16g': 16 * gb,
        '32g': 32 * gb
      },
      'mysql': {
        'mysql-sandbox': 192 * mb,
        'mysql-starter-256': 256 * mb,
        'mysql-starter-512': 512 * mb,
        'mysql-starter-1024': gb,
        'mysql-starter-2048': 2 * gb,
        'mysql-starter-4096': 4 * gb,
        'mysql-starter-8192': 8 * gb,
        'mysql-starter-16384': 16 * gb,
        'mysql-starter-32768': 32 * gb,
        'mysql-starter-65536': 64 * gb,
        'mysql-business-512': 512 * mb,
        'mysql-business-1024': gb,
        'mysql-business-2048': 2 * gb,
        'mysql-business-4096': 4 * gb,
        'mysql-business-8192': 8 * gb,
        'mysql-business-16384': 16 * gb,
        'mysql-business-32768': 32 * gb,
        'mysql-business-65536': 64 * gb
      },
      'elasticsearch': {
        'elasticsearch-sandbox': 320 * mb,
        'elasticsearch-starter-512': 512 * mb,
        'elasticsearch-starter-1024': gb,
        'elasticsearch-starter-2048': 2 * gb,
        'elasticsearch-starter-4096': 4 * gb,
        'elasticsearch-starter-8192': 8 * gb,
        'elasticsearch-starter-16384': 16 * gb,
        'elasticsearch-starter-32768': 32 * gb,
        'elasticsearch-starter-65536': 64 * gb,
        'elasticsearch-business-512': 512 * mb,
        'elasticsearch-business-1024': gb,
        'elasticsearch-business-2048': 2 * gb,
        'elasticsearch-business-4096': 4 * gb,
        'elasticsearch-business-8192': 8 * gb,
        'elasticsearch-business-16384': 16 * gb,
        'elasticsearch-business-32768': 32 * gb,
        'elasticsearch-business-65536': 64 * gb
      },
      'mongo': {
        'mongo-sandbox': 128 * mb,
        'mongo-starter-free': 128 * mb,
        'mongo-starter-256': 256 * mb,
        'mongo-starter-512': 512 * mb,
        'mongo-starter-1024': gb,
        'mongo-starter-2048': 2 * gb,
        'mongo-starter-4096': 4 * gb,
        'mongo-starter-8192': 8 * gb,
        'mongo-starter-16384': 16 * gb,
        'mongo-starter-32768': 32 * gb,
        'mongo-starter-65536': 64 * gb,
        'mongo-business-256': 256 * mb,
        'mongo-business-512': 512 * mb,
        'mongo-business-1024': gb,
        'mongo-business-2048': 2 * gb,
        'mongo-business-4096': 4 * gb,
        'mongo-business-8192': 8 * gb,
        'mongo-business-16384': 16 * gb,
        'mongo-business-32768': 32 * gb,
        'mongo-business-65536': 64 * gb
      },
      'postgresql': {
        'postgresql-sandbox': 128 * mb,
        'postgresql-starter-512': 512 * mb,
        'postgresql-starter-1024': gb,
        'postgresql-starter-2048': 2 * gb,
        'postgresql-starter-4096': 4 * gb,
        'postgresql-starter-8192': 8 * gb,
        'postgresql-starter-16384': 16 * gb,
        'postgresql-starter-32768': 32 * gb,
        'postgresql-starter-65536': 64 * gb,
        'postgresql-starter-131072': 128 * gb,
        'postgresql-starter-262144': 256 * gb,
        'postgresql-business-128': 128 * mb,
        'postgresql-business-256': 256 * mb,
        'postgresql-business-512': 512 * mb,
        'postgresql-business-1024': gb,
        'postgresql-business-2048': 2 * gb,
        'postgresql-business-4096': 4 * gb,
        'postgresql-business-8192': 8 * gb,
        'postgresql-business-16384': 16 * gb,
        'postgresql-business-32768': 32 * gb,
        'postgresql-business-65536': 64 * gb,
        'postgresql-business-131072': 128 * gb,
        'postgresql-business-262144': 256 * gb
      },
      'redis': {
        'free': 64 * mb,
        '128m': 128 * mb,
        '256m': 256 * mb,
        '512m': 512 * mb,
        '1g': gb,
        '2g': 2 * gb,
        '4g': 4 * gb,
        'redis-sandbox': 64 * mb,
        'redis-starter-128': 128 * mb,
        'redis-starter-256': 256 * mb,
        'redis-starter-512': 512 * mb,
        'redis-starter-1024': gb,
        'redis-starter-2048': 2 * gb,
        'redis-starter-4096': 4 * gb,
        'redis-starter-8192': 8 * gb,
        'redis-starter-16384': 16 * gb,
        'redis-starter-32768': 32 * gb,
        'redis-starter-65536': 64 * gb,
        'redis-business-128': 128 * mb,
        'redis-business-256': 256 * mb,
        'redis-business-512': 512 * mb,
        'redis-business-1024': gb,
        'redis-business-2048': 2 * gb,
        'redis-business-4096': 4 * gb,
        'redis-business-8192': 8 * gb,
        'redis-business-16384': 16 * gb,
        'redis-business-32768': 32 * gb,
        'redis-business-65536': 64 * gb
      },
      'influxdb': {
        'influxdb-sandbox': 128 * mb,
        'influxdb-starter-128': 128 * mb,
        'influxdb-starter-256': 256 * mb,
        'influxdb-starter-512': 512 * mb,
        'influxdb-starter-1024': gb,
        'influxdb-starter-2048': 2 * gb,
        'influxdb-starter-4096': 4 * gb,
        'influxdb-starter-8192': 8 * gb,
        'influxdb-starter-16384': 16 * gb,
        'influxdb-starter-32768': 32 * gb,
        'influxdb-starter-65536': 64 * gb
      }
    };

    if (sizes[dbType] != null && sizes[dbType][plan] != null) {
      return sizes[dbType][plan];
    }

    if (sizes['default'][plan] != null) {
      return sizes['default'][plan];
    }
    return default_size;
  };
});