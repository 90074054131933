define('database-dashboard/components/overview-stats', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OverviewStatsComponent = Ember.Component.extend({
    // Injected
    db: null,
    dbMetrics: null
  });

  exports.default = OverviewStatsComponent;
});