define('database-dashboard/components/sc-md-btn-submit', ['exports', 'database-dashboard/components/sc-md-btn'], function (exports, _scMdBtn) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ScMdBtnSubmitComponent = _scMdBtn.default.extend({
    layoutName: 'components/materialize-button',
    tagName: 'button',
    attributeBindings: ['type'],
    type: 'submit'
  });

  exports.default = ScMdBtnSubmitComponent;
});