define('database-dashboard/adapters/database-feature', ['exports', 'database-dashboard/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FeatureAdapter = _application.default.makeNestedResourceAdapter({
    parent: {
      path: 'databases',
      property: 'dbId'
    },
    resource: {
      path: 'features',
      property: 'featureId'
    }
  });

  exports.default = FeatureAdapter;
});