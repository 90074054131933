define('database-dashboard/components/sc-md-tabs', ['exports', 'database-dashboard/templates/components/sc-md-tabs', 'database-dashboard/components/md-tabs'], function (exports, _scMdTabs, _mdTabs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ScMdTabs = _mdTabs.default.extend({
    layout: _scMdTabs.default
  });

  exports.default = ScMdTabs;
});