define('database-dashboard/helpers/bytes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var formatBytes = function formatBytes(bytes) {
    var i, result, sizes;
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) {
      return 0 + ' B';
    }
    i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    result = bytes / Math.pow(1024, i);
    return Math.round(result * 10) / 10 + ' ' + sizes[[i]];
  };

  exports.default = formatBytes;
});