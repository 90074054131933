define('database-dashboard/components/single-cpu-chart', ['exports', 'database-dashboard/components/single-chart'], function (exports, _singleChart) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _singleChart.default.extend({
    formatAxis: function formatAxis(axis, max) {
      if (max < 100) {
        axis.y.max = 100;
      } else {
        axis.y.max = (Math.floor(max / 2) + 1) * 2;
      }
      return axis;
    }
  });
});