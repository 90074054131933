define('database-dashboard/components/materialize-card-action', ['exports', 'database-dashboard/components/md-card-action'], function (exports, _mdCardAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mdCardAction.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      Ember.deprecate('{{materialize-card-action}} has been deprecated. Please use {{md-card-action}} instead', false, {
        url: 'https://github.com/sgasser/ember-cli-materialize/issues/67'
      });
    }
  });
});