define('database-dashboard/controllers/dashboard/users', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(Ember.PromiseProxyMixin, {
    // Injected
    db: null,

    // Internal
    users: null,

    promiseFulfilledObs: Ember.observer('isFulfilled', function () {
      this.set('users', this.get('content'));
    })
  });
});