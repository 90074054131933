define('database-dashboard/helpers/date', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = DateHelper;


  window.moment.updateLocale('en', {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: "1s",
      m: "1m",
      mm: "%dm",
      h: "1h",
      hh: "%dh",
      d: "1d",
      dd: "%dd",
      M: "1mo",
      MM: "%dmo",
      y: "1y",
      yy: "%dy"
    }
  });

  var helpers = {
    dateToMonth: function dateToMonth(params) {
      var date = params[0];
      if (!date) {
        return "n/a";
      }
      var locale = window.navigator.userLanguage || window.navigator.language;
      // When there are several locales: "fren" for instance
      locale = locale.split("")[0];
      return date.toLocaleDateString(locale, { month: "long" }) + " " + date.getFullYear();
    },
    timeAgo: function timeAgo(params) {
      var date = params[0];
      return window.moment(date).fromNow();
    },
    minutesToTime: function minutesToTime(params) {
      var mins = params[0];
      var dayMins = 60 * 24;
      var nbDays = parseInt(mins / dayMins % dayMins);
      mins -= nbDays * dayMins;
      var nbDaysLabel = nbDays > 1 ? "days " : "day ";
      return nbDays + nbDaysLabel + parseInt(mins / 60 % 60) + "h " + parseInt(mins % 60) + "min";
    },
    toDate: function toDate(params) {
      var date = params[2];
      if (!date) {
        return "n/a";
      }
      if (typeof date === "string") {
        date = new Date(date);
      }
      return date.toDateString();
    },
    toDateTime: function toDateTime(params) {
      var date = params[0];
      if (!date) {
        return "n/a";
      }
      if (typeof date === "string") {
        date = new Date(date);
      }
      return date.toDateString() + " " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
    },
    toNumericDateTime: function toNumericDateTime(params) {
      var date = params[0];
      if (!date) {
        return "n/a";
      }
      // L:  Date (in local format)
      // LT: Time (without seconds)
      return window.moment(date).format('L LT');
    },
    toNumericDate: function toNumericDate(params) {
      var date = params[0];
      if (!date) {
        return "n/a";
      }
      // L:  Date (in local format)
      return window.moment(date).format('L');
    },
    isToday: function isToday(params) {
      var date = params[0];
      return window.moment(date).isSame(new Date(), 'day');
    },
    nanoToMilli: function nanoToMilli(params) {
      return params[0] / 1000000;
    },
    formatDuration: function formatDuration(params) {
      var time = params[0];
      var unit = params[1];
      var duration = window.moment.duration(time, unit);
      var format = 's[s] S[ms]';

      if (duration.asMinutes() > 2) {
        format = 'm[m] s[s]';
      }

      if (duration.asHours() > 2) {
        format = 'H[h] m[m]';
      }

      return window.moment.duration(time, unit).format(format);
    }
  };

  function DateHelper(app) {
    for (var key in helpers) {
      app[Ember.String.capitalize(key) + 'Helper'] = Ember.Helper.helper(helpers[key]);
    }
  }
});