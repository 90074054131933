define('database-dashboard/components/database/database-management', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    confirmationModal: Ember.inject.service('confirmation-modal'),
    // Inject
    db: null,

    // Hooks
    didInsertElement: function didInsertElement() {
      this.loadDatabases();
    },


    hasLoadingOverlay: Ember.computed('loadingDatabases', 'creating', 'deleting', function () {
      return this.get('loadingDatabases') || this.get('creating') || this.get('deleting');
    }),

    // Computed
    content: Ember.computed('databases.[]', function () {
      if (!this.get('databases')) {
        return ['first'];
      } else {
        return this.get('databases');
      }
    }),

    // Methods
    loadDatabases: function loadDatabases() {
      var _this = this;

      this.set('loadingDatabases', true);
      this.get('db').databases().then(function (dbs) {
        _this.set('databases', dbs);
      }).catch(function (res) {
        _this.get('notifier').notify('Something wrong happened');
      }).finally(function () {
        _this.set('loadingDatabases', false);
      });
    },
    deleteDatabase: function deleteDatabase(db) {
      var _this2 = this;

      this.set('deleting', true);
      this.get('db').deleteDatabase(db.name).then(function () {
        _this2.loadDatabases();
        _this2.get('notifier').notify('Database ' + db.name + ' has been deleted.');
      }).catch(function (res) {
        _this2.get('notifier').notify('Something wrong happened: ' + res.error);
      }).finally(function () {
        _this2.set('deleting', false);
      });
    },
    resetDatabase: function resetDatabase(db) {
      var _this3 = this;

      this.set('deleting', true);
      this.get('db').resetDatabase(db.name).then(function () {
        _this3.loadDatabases();
        _this3.get('notifier').notify('Database ' + db.name + ' has been reseted.');
      }).catch(function (res) {
        _this3.get('notifier').notify('Something wrong happened: ' + res.error);
      }).finally(function () {
        _this3.set('deleting', false);
      });
    },


    actions: {
      confirmDeleteDatabase: function confirmDeleteDatabase(db) {
        var _this4 = this;

        this.get('confirmationModal').display({
          prompt: 'Do you really want to delete the database "' + db.name + '"?',
          extraMsg: Ember.String.htmlSafe('This operation will delete the database "' + db.name + '", including all its data, ' + 'be sure you don\'t need these data anymore or that you have an up-to-date backup. ' + '<br><br>To validate the operation please type <b>the name of the database</b>:'),
          validationText: db.name,
          okLabel: 'drop',
          action: function action() {
            return _this4.deleteDatabase(db);
          }
        });
      },
      confirmResetDatabase: function confirmResetDatabase(db) {
        var _this5 = this;

        this.get('confirmationModal').display({
          prompt: 'Do you really want to reset the database "' + db.name + '"?',
          extraMsg: Ember.String.htmlSafe('This operation will reset the database "' + db.name + '", ' + 'the database will keep existing but its data will be wiped. ' + 'Be sure you don\'t need these data anymore or that you have an up-to-date backup. ' + '<br><br>To validate the operation please type <b>the name of the database</b>:'),
          validationText: db.name,
          okLabel: 'reset',
          action: function action() {
            return _this5.resetDatabase(db);
          }
        });
      },
      createDatabase: function createDatabase() {
        var _this6 = this;

        var name = this.get('newDbName');
        this.set('errors', null);
        this.set('creating', true);
        this.get('db').createDatabase(name).then(function () {
          _this6.loadDatabases();
          _this6.set('newDbName', '');
          _this6.get('notifier').notify('Database ' + name + ' has been created.');
        }).catch(function (res) {
          var errors = res.errors ? Object.values(res.errors).toString() : res.error;
          _this6.get('notifier').notify('Something wrong happened: ' + errors);
          _this6.set('errors', [errors]);
        }).finally(function () {
          _this6.set('creating', false);
        });
      }
    }
  });
});