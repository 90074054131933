define('database-dashboard/helpers/start-with', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.startWith = startWith;
  function startWith(params) {
    var value = params[0];
    var comp = params[1];
    return value.substr(0, comp.length) == comp;
  }

  exports.default = Ember.Helper.helper(startWith);
});