define('database-dashboard/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    confirmationModal: Ember.inject.service('confirmation-modal'),
    layoutOffset: false,
    useFullLayout: Ember.computed.and('session.authenticated', 'layoutOffset'),
    init: function init() {
      this._super.apply(this, arguments);
      Ember.$('.app-loader').remove();
    }
  });
});