define('database-dashboard/helpers/to-bytes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var KB = 1024;
  var MB = KB * 1024;
  var GB = MB * 1024;
  var TB = GB * 1024;

  exports.default = Ember.Helper.helper(function (params) {
    if (params.length === 2) {
      var a = parseFloat(params[0]);
      var b = parseFloat(params[1]);

      var unitFinder = a > b ? a : b;
      if (unitFinder == null || isNaN(unitFinder) || unitFinder < 0) {
        return 'n/a';
      }
      if (unitFinder / TB >= 1) {
        a = (a / TB).toFixed(2) / 1;
        b = (b / TB).toFixed(2) / 1;
        return a + ' / ' + b + ' TB';
      } else if (unitFinder / GB >= 1) {
        a = (a / GB).toFixed(2) / 1;
        b = (b / GB).toFixed(2) / 1;
        return a + ' / ' + b + ' GB';
      } else if (unitFinder / MB >= 1) {
        a = (a / MB).toFixed(0) / 1;
        b = (b / MB).toFixed(0) / 1;
        return a + ' / ' + b + ' MB';
      } else if (unitFinder / KB >= 1) {
        a = (a / KB).toFixed(0) / 1;
        b = (b / KB).toFixed(0) / 1;
        return a + ' / ' + b + ' KB';
      } else {
        return a + ' / ' + b + ' B';
      }
    } else {
      var size = parseFloat(params[0]);
      if (size == null || isNaN(size) || size < 0) {
        return 'n/a';
      }
      if (size / TB >= 1) {
        size = (size / TB).toFixed(2) / 1;
        return size + ' TB';
      } else if (size / GB >= 1) {
        size = (size / GB).toFixed(2) / 1;
        return size + ' GB';
      } else if (size / MB >= 1) {
        size = (size / MB).toFixed(0) / 1;
        return size + ' MB';
      } else if (size / KB >= 1) {
        size = (size / KB).toFixed(0) / 1;
        return size + ' KB';
      } else {
        return size + ' B';
      }
    }
  });
});