define('database-dashboard/routes/dashboard/pg-query-statistics', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Query statistics',

    model: function model() {
      var db = this.modelFor('dashboard').db;
      return Ember.RSVP.hash({
        db: db
      });
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);
      model.db.get('hasPgStatStatements').then(function (ok) {
        if (ok) {
          _this.controllerFor('dashboard.pg-query-statistics').send('reload', { dontNotify: true });
        }
      });
    },


    actions: {
      didTransition: function didTransition() {
        this.send('updateSelectedTab', 'pg-query-statistics');
      }
    }
  });
});