define('database-dashboard/adapters/plan', ['exports', 'database-dashboard/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PlanAdapter = _application.default.extend({});

  exports.default = PlanAdapter;
});