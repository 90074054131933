define('database-dashboard/services/confirmation-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    context: null,

    display: function display(context) {
      this.set('context', context);
      Ember.$('#confirmationModal').modal('open');
    }
  });
});