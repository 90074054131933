define('database-dashboard/components/sc-modal', ['exports', 'database-dashboard/templates/components/sc-modal'], function (exports, _scModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _scModal.default,

    // Injected properties
    id: null,
    close: null,
    modalContext: null,
    autoOpen: false,
    fixedFooter: true,

    didInsertElement: function didInsertElement() {
      var div = Ember.$('#' + this.get('id'));
      div.modal();
      if (this.get('autoOpen')) {
        div.modal('open', {
          complete: Ember.run.bind(this, 'complete')
        });
      }
    },
    complete: function complete() {
      if (this.get('close')) {
        this.get('close')();
      }
      var onClose = this.get('modalContext.onClose');
      if (onClose) {
        onClose();
      }
    }
  });
});