define('database-dashboard/helpers/to-timezone', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (hour, tz) {
    var offset = _moment.default.tz.zone(tz).utcOffset((0, _moment.default)()) / 60;
    var tzTime = (hour - offset) % 24;
    if (tzTime < 0) {
      tzTime += 24;
    }
    return tzTime;
  };
});