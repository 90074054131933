define('database-dashboard/router', ['exports', 'database-dashboard/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AppRouter = Ember.Router.extend({
    location: _environment.default.locationType
  });

  AppRouter.map(function () {
    this.route('sso');
    this.route('you-are-lost');

    this.route('index', { path: '/' }, function () {
      this.route('dashboard', { resetNamespace: true, path: '/dashboard/:id' }, function () {
        this.route('overview');
        this.route('users');
        this.route('databases');
        this.route('backups');
        this.route('logs');
        this.route('logs-archives');
        this.route('pg-running-queries');
        this.route('pg-query-statistics');
        this.route('advanced');
        this.route('metrics');
      });
    });
  });

  exports.default = AppRouter;
});