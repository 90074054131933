define('database-dashboard/components/single-memory-chart', ['exports', 'database-dashboard/components/single-chart', 'database-dashboard/helpers/instance-size', 'moment'], function (exports, _singleChart, _instanceSize, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _singleChart.default.extend({
    db: null,
    instance: null,

    formatValues: function formatValues(val, type) {
      var i, j, len, limit, previous, result, sample;
      limit = (0, _instanceSize.default)(this.get('db.typeName'), this.get('db.plan'), this.get('instance.type'));
      if (type === "memory") {
        return this._super(val, type);
      } else {
        result = {
          data: [[type], ['x']],
          max: 0,
          type: type
        };
        for (i = j = 0, len = val.length; j < len; i = ++j) {
          sample = val[i];
          sample.value = sample.value || 0;
          if (sample.value <= limit * 0.02) {
            sample.value = null;
            if (result.data[0].length > 0) {
              previous = result.data[0][result.data[0].length - 1];
              if (previous !== null && previous !== 0) {
                sample.value = 0;
              }
            }
          } else {
            if (result.data[0].length > 0 && result.data[0][result.data[0].length - 1] === null) {
              result.data[0][result.data[0].length - 1] = 0;
            }
            if (sample.value < 0) {
              sample.value = 0;
            }
            if (sample.value > result.max) {
              result.max = sample.value;
            }
            if (sample.value === 0 && i <= 1) {
              continue;
            }
            if (sample.value === 0 && i >= val.length - 2) {
              continue;
            }
          }
          result.data[0].push(sample.value);
          result.data[1].push((0, _moment.default)(sample.time).toDate());
        }
        return result;
      }
    },
    formatAxis: function formatAxis(axis, max, type) {
      if (type === "memory") {
        return this.formatMemoryAxis(axis, max);
      } else {
        return this.formatSwapAxis(axis, max);
      }
    },
    formatMemoryAxis: function formatMemoryAxis(axis, max) {
      var limit;
      limit = (0, _instanceSize.default)(this.get('db.typeName'), this.get('db.plan'), this.get('instance.type'));

      axis.y.tick.values = [limit / 2, limit];
      if (limit > max) {
        axis.y.max = limit;
      } else {
        axis.y.max = max;
      }
      return axis;
    },
    formatSwapAxis: function formatSwapAxis(axis, max) {
      if (max > axis.y.max / 15) {
        axis.y.max += max;
      }
      return axis;
    },
    formatGrid: function formatGrid(axis, max, type) {
      var grid, limit;
      limit = (0, _instanceSize.default)(this.get('db.typeName'), this.get('db.plan'), this.get('instance.type'));
      if (type === "swap") {
        grid = this.get("grid");
        if (max > axis.y.max / 15) {
          grid.y.lines = [{
            value: limit
          }];
        }
        this.propertyWillChange("grid");
        this.set("grid", grid);
        return this.propertyDidChange("grid");
      }
    },
    formatData: function formatData(metricsData) {
      var customColors, data;
      if (metricsData[0][0] === "swap") {
        data = this.get("tempData");
        data.columns.push(metricsData[0]);
      } else {
        data = {
          order: function order(d1) {
            return d1.id === "swap";
          },
          columns: metricsData,
          x: 'x',
          onmouseover: this.dataMouseoverBuilder(),
          types: {},
          groups: [["memory", "swap"]]
        };
      }
      data.types[metricsData[0][0]] = "area";
      customColors = this.get("customColors");
      if (customColors != null) {
        data.colors = customColors;
      }
      return data;
    },
    autoUpdateFormater: function autoUpdateFormater(data, results) {
      var dataIndex, j, len, limit, previousValue, result, value, x, xDataIndex;
      x = false;
      limit = (0, _instanceSize.default)(this.get('db.typeName'), this.get('db.plan'), this.get('instance.type'));
      for (j = 0, len = results.length; j < len; j++) {
        result = results[j];
        dataIndex = this.getDataIndex(data.columns, result.type);
        if (dataIndex !== -1) {
          if (result.metrics) {
            value = result.metrics[0].value;
            if (value < limit * 0.02) {
              value = 0;
            }
            if (value === 0 && result.type === "swap") {
              previousValue = data.columns[dataIndex][data.columns[dataIndex].length - 1];
              if (previousValue === 0 || previousValue === null) {
                value = null;
              }
            } else {
              if (previousValue === null) {
                data.columns[dataIndex][data.columns[dataIndex].length - 1] = 0;
              }
            }
            data.columns[dataIndex].push(value);
            if (!x) {
              x = true;
              xDataIndex = this.getDataIndex(data.columns, 'x');
              data.columns[xDataIndex].push((0, _moment.default)(result.metrics[0].time).toDate());
              data.columns[xDataIndex].splice(1, 1);
            }
          } else {
            data.columns[dataIndex].push(null);
          }
          data.columns[dataIndex].splice(1, 1);
        }
      }
      return data;
    },
    autoUpdateMaxFormatter: function autoUpdateMaxFormatter(axis, data) {
      var cur, index, j, k, len, max, metricsIndex, ref, ref1, xDataIndex;
      max = axis.y.max;
      xDataIndex = this.getDataIndex(data.columns, 'x');
      ref = data.columns[0];
      for (index = j = 0, len = ref.length; j < len; index = ++j) {
        for (metricsIndex = k = 0, ref1 = data.columns.length - 1; 0 <= ref1 ? k <= ref1 : k >= ref1; metricsIndex = 0 <= ref1 ? ++k : --k) {
          cur = 0;
          if (metricsIndex !== xDataIndex) {
            cur += data.columns[metricsIndex][index];
          }
          if (cur > max) {
            max = cur;
          }
        }
      }
      return axis.y.max = max;
    }
  });
});