define('database-dashboard/components/c3-chart', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['c3-chart-component'],
    data: {},
    axis: {},
    regions: {},
    bar: {},
    pie: {},
    donut: {},
    gauge: {},
    grid: {},
    legend: {},
    tooltip: {},
    subchart: {},
    zoom: {},
    point: {},
    line: {},
    area: {},
    size: {},
    padding: {},
    color: {},
    transition: {},
    _chart: null,

    chart: Ember.computed('config', function () {
      if (Ember.isEmpty(this.get('_chart'))) {
        var container = this.$().get(0);
        if (Ember.isEmpty(container)) {
          return void 0;
        } else {
          var config = this.get('_config');
          var chart = window.c3.generate(config);
          this.set('_chart', chart);
          return chart;
        }
      } else {
        return this.get('_chart');
      }
    }),

    _config: Ember.computed('data', 'axis', 'regions', 'bar', 'pie', 'donut', 'gauge', 'grid', 'legend', 'tooltip', 'subchart', 'zoom', 'point', 'line', 'area', 'size', 'padding', 'color', 'transition', function () {
      var _this = this;

      var c = this.getProperties(['data', 'axis', 'regions', 'bar', 'pie', 'donut', 'gauge', 'grid', 'legend', 'tooltip', 'subchart', 'zoom', 'point', 'line', 'area', 'size', 'padding', 'color', 'transition']);
      Ember.A(['oninit', 'onrendered', 'onmouseover', 'onmouseout', 'onresize', 'onresized']).forEach(function (eventname) {
        c[eventname] = function () {
          _this.sendAction(eventname, _this);
        };
      });
      c.bindto = this.$().get(0);
      return c;
    }),

    dataDidChange: Ember.observer('data', function () {
      var chart = this.get('chart');
      if (Ember.isEmpty(chart)) {
        return;
      }
      var data = this.get('data');
      if (Ember.isEmpty(data)) {
        return;
      }
      chart.load(data);
    }),

    didInsertElement: function didInsertElement() {
      var controller = this.get('targetObject');
      var propertyKey = void 0;
      var data = this.get('data');
      for (var prop in controller) {
        if (controller.hasOwnProperty(prop)) {
          if (controller[prop] === data) {
            propertyKey = prop;
            break;
          }
        }
      }
      if (!Ember.isEmpty(propertyKey)) {
        controller.addObserver(propertyKey, this, this.dataDidChange);
      }
      this.dataDidChange();
    }
  });
});