define('database-dashboard/components/pg-query-statistics-query', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    query: null,
    selectedQuery: null,

    // internal
    isEditing: false,

    obsSelectedQuery: Ember.observer('selectedQuery', function () {
      if (this.get('selectedQuery') !== this.get('query')) {
        this.set('isEditing', false);
      }
    }),

    click: function click() {
      if (!this.get('isEditing')) {
        this.set('isEditing', true);
        this.get('selectionChanged')(this.get('query'));
      }
    }
  });
});