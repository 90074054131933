define('database-dashboard/routes/dashboard/overview', ['exports', 'database-dashboard/mixins/retry-until-success', 'database-dashboard/models/database-metrics', 'database-dashboard/models/database-health', 'database-dashboard/models/database-lag', 'database-dashboard/models/database'], function (exports, _retryUntilSuccess, _databaseMetrics, _databaseHealth, _databaseLag, _database) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_retryUntilSuccess.default, {
    titleToken: 'Overview',

    model: function model() {
      return this.modelFor('dashboard');
    },
    startMetricsReloader: function startMetricsReloader(controller, databaseID) {
      var _this = this;

      var promise = this.retryUntilSuccess("database-metrics", function () {
        return _databaseMetrics.default.get(databaseID);
      });
      promise.then(function (metrics) {
        controller.set('dbMetrics', metrics);
        setTimeout(function () {
          _this.startMetricsReloader(controller, databaseID);
        }, 10000);
      });
      return promise;
    },
    startHealthReloader: function startHealthReloader(controller, databaseID) {
      var _this2 = this;

      return _databaseHealth.default.get(databaseID).then(function (dbHealth) {
        controller.set('dbHealth', dbHealth);
      }, function () {
        controller.set('dbHealth', null);
      }).always(function () {
        setTimeout(function () {
          _this2.startHealthReloader(controller, databaseID);
        }, 60000);
      });
    },
    startLagReloader: function startLagReloader(controller, databaseID) {
      var _this3 = this;

      return _databaseLag.default.get(databaseID).then(function (dbLag) {
        controller.set('dbLag', dbLag);
      }, function () {
        controller.set('dbLag', null);
      }).always(function () {
        setTimeout(function () {
          _this3.startLagReloader(controller, databaseID);
        }, 60000);
      });
    },
    setupController: function setupController(controller, model) {
      var databaseID = model.db.get('id');
      if (model.db.get('typeName') == _database.dbTypes.ELASTICSEARCH) {
        this.startHealthReloader(controller, databaseID);
      } else if (model.db.get('cluster') && (model.db.get('typeName') == _database.dbTypes.PGSQL || model.db.get('typeName') == _database.dbTypes.MYSQL)) {
        this.startLagReloader(controller, databaseID);
      }
      this.startMetricsReloader(controller, databaseID);
      this._super(controller, model);
    },


    actions: {
      didTransition: function didTransition() {
        this.send('updateSelectedTab', 'overview');
      }
    }
  });
});