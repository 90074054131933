define('database-dashboard/adapters/database', ['exports', 'database-dashboard/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DatabaseAdapter = _application.default.extend({});

  exports.default = DatabaseAdapter;
});