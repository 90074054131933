define('database-dashboard/components/database-general', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      Ember.$('#tls-support.tooltipped').tooltip();
      Ember.$('#internet-access.tooltipped').tooltip();
    }
  });
});