define("database-dashboard/components/single-diskio-chart", ["exports", "database-dashboard/components/single-chart"], function (exports, _singleChart) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _singleChart.default.extend({
    // values contains the JSON returned by the DB API call
    // types contains what is defined in diskioUsedTypes in db-disk-metrics
    formatValues: function formatValues(values, type) {
      // We want to use the `reads` or `writes` keys in the diskio metrics
      var formattedValues = values["diskio_metrics"].flatMap(function (val) {
        var value = val["diskio_" + type];
        return { time: val["time"], value: value };
      });
      return this._super(formattedValues, type);
    },

    formatAxis: function formatAxis(axis, max) {
      // The max y-axis value is the next hundred of the max.
      var hundreds = Math.round(max / 100);
      var max_y = (hundreds + 1) * 100;

      // Do not overwrite the scale when another metrics has the highest value.
      if (max_y > axis.y.max) {
        axis.y.max = max_y;
      }
      axis.y.min = 0;
      return axis;
    },

    // we need to override the formatData function to display both reads and writes.
    formatData: function formatData(metricsData) {
      var customColors, data;
      if (metricsData[0][0] === "writes") {
        data = this.get("tempData");
        data.columns.push(metricsData[0]);
      } else {
        // if we format the reads data
        data = {
          order: function order(d1) {
            return d1.id === "writes";
          },
          columns: metricsData,
          x: 'x',
          onmouseover: this.dataMouseoverBuilder(),
          types: {}
        };
      }
      data.types[metricsData[0][0]] = "area";
      customColors = this.get("customColors");
      if (customColors != null) {
        data.colors = customColors;
      }
      return data;
    }
  });
});