define('database-dashboard/routes/dashboard/backups', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    confirmationModal: Ember.inject.service('confirmation-modal'),
    titleToken: 'Backups',

    model: function model() {
      var dashboard = this.modelFor('dashboard');
      var db = dashboard.db;
      var plan = dashboard.plan;

      return Ember.RSVP.hash({
        db: db,
        plan: plan,
        dbBackups: this.store.query('databaseBackup', { dbId: db.get('id') })
      });
    },


    actions: {
      didTransition: function didTransition() {
        this.send('updateSelectedTab', 'backups');
      },


      downloadBackup: function downloadBackup(downloadUrl) {
        var request = Ember.$.ajax(downloadUrl, {
          method: 'GET',
          dataType: 'json'
        });
        var backupPromise = new Ember.RSVP.Promise(function (resolve, reject) {
          return request.then(resolve, reject);
        });
        return backupPromise.then(function (value) {
          window.location = value.download_url;
        });
      },
      restoreBackup: function restoreBackup(backup) {
        var _this = this;

        this.get('confirmationModal').display({
          okLabel: 'Restore',
          prompt: 'Do you really want to restore the backup ' + backup.get('name') + '?',
          extraMsg: Ember.String.htmlSafe('This operation will restore the backup "' + backup.get('name') + '". ' + ('The database will be restored with data from: <br> ' + backup.get('createdAt')) + ('<br><br>To validate the operation please type <b>"' + backup.get('name') + '"</b>:')),
          validationText: backup.get('name'),
          action: function action() {
            backup.restore().done(function () {
              _this.get('notifier').notify("This backup is being restored");
            }).fail(function () {
              _this.get('notifier').notify("Fail to restore backup");
            });
          }
        });
      }
    }
  });
});