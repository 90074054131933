define('database-dashboard/controllers/dashboard/metrics', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: {
      metricsTime: {
        replace: true
      }
    },
    db: null,
    metrics: null,
    tooltipPos: null,
    metricsTime: 3,
    metricsTimeValues: [{
      value: 3,
      title: '3h'
    }, {
      value: 12,
      title: '12h'
    }, {
      value: 24,
      title: '24h'
    }, {
      value: 48,
      title: '48h'
    }, {
      value: 72,
      title: '72h'
    }],

    instanceSortProperties: ['type:asc', 'privateIp:asc'],
    instancesByType: Ember.computed.sort('model.db.instances', 'instanceSortProperties'),

    timezones: _moment.default.tz.names(),
    graphTimezone: _moment.default.tz.guess(),
    actions: {
      tooltipUpdate: function tooltipUpdate(tooltip) {
        this.set('tooltipPos', tooltip);
      },
      metricsTimeUpdate: function metricsTimeUpdate(time) {
        this.set('metricsTime', time);
      }
    }
  });
});