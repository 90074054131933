define('database-dashboard/models/database-user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    readOnly: _emberData.default.attr('boolean'),
    password: _emberData.default.attr('string'),
    passwordConfirmation: _emberData.default.attr('string'),

    // Added relationships
    database: _emberData.default.belongsTo('database'),

    isProtectedUser: Ember.computed('database', function () {
      return this.get('name') === this.get('database.resourceId') || this.get('database.typeName') === "mongo" && this.get('name') === "oplogger";
    })
  });
});