define('database-dashboard/routes/dashboard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: '',

    beforeModel: function beforeModel(transition) {
      // Requested database is not the one we are authenticated for
      if (this.get('session.databaseId') !== transition.params.dashboard.id) {
        this.get('session').clear();
        this.redirectToLost(transition);
      }
    },
    redirectToLost: function redirectToLost() {
      this.transitionTo('you-are-lost');
    },
    model: function model(params) {
      var store = this.store;
      var database = store.findRecord('database', params.id);

      return database.then(function (db) {
        return Ember.RSVP.hash({
          db: db,
          version: db.get('version'),
          plan: db.get('currentPlan')
        }).then(function (model) {
          model.db.get('currentOperation.id');
          return model;
        });
      });
    },
    setupController: function setupController(controller, model) {
      var displayName = model.db.get('displayName');
      var title = 'Scalingo ' + displayName + ' dashboard';
      this.set('titleToken', title);
      this.send('updateNavTitle', title);

      var tabsContent = [{ id: 'overview', title: 'Overview' }, { id: 'logs', title: 'Logs' }, { id: 'metrics', title: 'Metrics' }, { id: 'backups', title: 'Backups' }];
      if (model.db.get('canModifyUsers')) {
        tabsContent.splice(tabsContent.length, 0, { id: 'users', title: 'Users' });
      }
      if (model.db.get('canModifyDatabases')) {
        tabsContent.splice(tabsContent.length, 0, { id: 'databases', title: 'Databases' });
      }

      if (model.db.get('typeName') == "postgresql") {
        tabsContent.splice(tabsContent.length, 0, { id: 'advanced', title: 'Extensions' });
        tabsContent.splice(3, 0, { id: 'pg-query-statistics', title: 'Query Statistics' });
        tabsContent.splice(3, 0, { id: 'pg-running-queries', title: 'Running Queries' });
      } else {
        tabsContent.splice(tabsContent.length, 0, { id: 'advanced', title: 'Advanced' });
      }

      this.controllerFor('index').setProperties({
        appName: model.db.get('appName'),
        dbType: model.db.get('displayName').toLowerCase(),
        navColor: model.db.get('themeColor'),
        tabsContent: tabsContent
      });
    },


    actions: {
      updateSelectedTab: function updateSelectedTab(tab) {
        this.controllerFor('index').set('selectedTab', tab);
      }
    }

  });
});