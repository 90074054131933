define('database-dashboard/models/database-metrics', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * DatabaseMetrics structure.
   * Key-value pair is omitted by the API if value is invalid, such as -1.
   *
   * {
   *   cpu_usage: number,
   *   database_stats: {
   *     data_size: number,
   *
   *     current_connections: number, // pgsql and mysql
   *     max_connections: number,     // pgsql and mysql
   *
   *     index_size: number,          // mongo only
   *   },
   *   errors: {
   *     cpu: string,
   *     memory: string
   *     real_disk_size: string
   *     database_size: string
   *   },
   *   memory: {
   *     memory: number
   *     memory_max: number
   *     memory_limit: number
   *     swap: number
   *     swap_max: number
   *     swap_limit: number
   *   },
   *   real_disk_size: number
   * }
   *
   */
  var DatabaseMetrics = Ember.Object.extend({});

  DatabaseMetrics.reopenClass({
    get: function get(dbId) {
      var url = window.EmberENV.apiBaseURI + '/databases/' + dbId + '/metrics';
      return Ember.$.ajax({ url: url });
    }
  });

  exports.default = DatabaseMetrics;
});