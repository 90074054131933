define('database-dashboard/helpers/instance-role', ['exports', 'database-dashboard/models/database'], function (exports, _database) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.instanceRole = instanceRole;
  function instanceRole(args) {
    var instance = args[0];
    var metrics = args[1];
    var dbTypeName = args[2];
    var instanceID = instance.get('id');
    var instanceType = instance.get('typeString');

    var clusteredDatabase = [_database.dbTypes.MONGO, _database.dbTypes.ELASTICSEARCH, _database.dbTypes.MYSQL, _database.dbTypes.PGSQL, _database.dbTypes.REDIS, _database.dbTypes.INFLUX];
    if (clusteredDatabase.indexOf(dbTypeName) == -1) {
      return instanceID;
    }

    // For multi-nodes database, display the instance type along with the instance
    // ID (e.g. Node, Gateway, etc)
    if (instanceType != '') {
      return instanceID + ' (' + instanceType + ')';
    }
    return instanceID;
  }

  exports.default = Ember.Helper.helper(instanceRole);
});