define('database-dashboard/models/database', ['exports', 'ember-data', 'database-dashboard/mixins/postgresql-database-mixin', 'database-dashboard/models/operation'], function (exports, _emberData, _postgresqlDatabaseMixin, _operation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.disableDiskMetricsGraphFlag = exports.dbTypes = exports.featurestatus = exports.features = undefined;
  var features = exports.features = {
    PUBLICLY_AVAILABLE: "publicly-available",
    FORCE_SSL: "force-ssl",
    OPLOG: "oplog",
    REDIS_RDB: "redis-rdb",
    REDIS_AOF: "redis-aof",
    REDIS_CACHE: "redis-cache"
  };

  var featurestatus = exports.featurestatus = {
    ACTIVATED: "ACTIVATED",
    PENDING: "PENDING",
    FAILED: "FAILED",
    NOTFOUND: "NOTFOUND"
  };

  var dbTypes = exports.dbTypes = {
    ELASTICSEARCH: "elasticsearch",
    INFLUX: "influxdb",
    MONGO: "mongo",
    MYSQL: "mysql",
    PGSQL: "postgresql",
    REDIS: "redis"

    // Variable containing the flag that need to be present to enable disk/diskIO graphs.
  };var disableDiskMetricsGraphFlag = exports.disableDiskMetricsGraphFlag = "disable-disk-metrics-graph";

  exports.default = _emberData.default.Model.extend(_postgresqlDatabaseMixin.default, {
    appName: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    features: _emberData.default.attr(),
    encryptionAtRest: _emberData.default.attr('boolean'),
    // hostname is the hostname and port concatenated for all the instances
    hostname: _emberData.default.attr('string'),
    plan: _emberData.default.attr('string'),
    resourceId: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    typeId: _emberData.default.attr('string'),
    typeName: _emberData.default.attr('string'),
    readableVersion: _emberData.default.attr('string'),
    versionId: _emberData.default.attr('string'),
    users: _emberData.default.hasMany('databaseUser', { async: true }),
    nextVersionWarning: _emberData.default.attr('string'),
    cluster: _emberData.default.attr('boolean'),
    flags: _emberData.default.attr(),

    // Specific MongoDB
    mongoReplSetName: _emberData.default.attr('string'),
    // Specific PostgreSQL
    postgresqlConfig: _emberData.default.attr(),

    // Added relationships
    instances: _emberData.default.hasMany('instance'),
    backups: _emberData.default.hasMany('databaseBackup'),
    type: _emberData.default.belongsTo('databaseType'),
    version: _emberData.default.belongsTo('databaseTypeVersion', { inverse: "databases" }),
    nextVersion: _emberData.default.belongsTo('databaseTypeVersion', { inverse: null }),

    currentOperation: _emberData.default.belongsTo('operation'),

    // Backups
    periodicBackupsEnabled: _emberData.default.attr('boolean'),
    periodicBackupsScheduledAt: _emberData.default.attr(),
    firstPitrBackup: _emberData.default.attr('date'),

    // Private properties
    checkInterval: 1500,
    upgradeIntervalId: null,

    hasInternetAccess: Ember.computed('features.[]', function () {
      return this.hasFeature(features.PUBLICLY_AVAILABLE);
    }),
    hasForceSSL: Ember.computed('features.[]', function () {
      return this.hasFeature(features.FORCE_SSL);
    }),

    url: Ember.computed(function () {
      var typeName = this.get('typeName');
      if (typeName == dbTypes.REDIS) {
        return this.get('noTlsUrl');
      }

      if (typeName == dbTypes.INFLUX || typeName == dbTypes.ELASTICSEARCH) {
        if (this.hasFeature(features.FORCE_SSL)) {
          return this.get('tlsUrl');
        }
      } else if (this.get('version.hasTLS')) {
        return this.get('tlsUrl');
      }

      return this.get('noTlsUrl');
    }),

    noTlsUrl: Ember.computed(function () {
      var typeName = this.get('typeName');
      var hostname = this.get('hostname');
      var resourceId = this.get('resourceId');
      var replicasetName = this.get('mongoReplSetName');
      if (replicasetName) {
        replicasetName = "?replicaSet=".concat(replicasetName);
      }

      switch (typeName) {
        case dbTypes.MONGO:
          return 'mongodb://<user>:<password>@' + hostname + '/' + resourceId + replicasetName;
        case dbTypes.MYSQL:
          return 'mysql://<user>:<password>@' + hostname + '/' + resourceId;
        case dbTypes.PGSQL:
          return 'postgres://<user>:<password>@' + hostname + '/' + resourceId;
        case dbTypes.REDIS:
          return 'redis://<user>:<password>@' + hostname;
        case dbTypes.ELASTICSEARCH:
          return 'http://<user>:<password>@' + hostname;
        case dbTypes.INFLUX:
          return 'http://<user>:<password>@' + hostname + '/' + resourceId;
      }
    }),

    tlsUrl: Ember.computed(function () {
      var typeName = this.get('typeName');
      var hostname = this.get('hostname');
      var resourceId = this.get('resourceId');
      var replicasetName = this.get('mongoReplSetName');
      if (replicasetName) {
        replicasetName = "?replicaSet=".concat(replicasetName);
      }

      switch (typeName) {
        case dbTypes.MONGO:
          return 'mongodb://<user>:<password>@' + hostname + '/' + resourceId + replicasetName + '&ssl=true';
        case dbTypes.MYSQL:
          return 'mysql://<user>:<password>@' + hostname + '/' + resourceId + '?useSSL=true&verifyServerCertificate=false';
        case dbTypes.PGSQL:
          return 'postgres://<user>:<password>@' + hostname + '/' + resourceId + '?sslmode=prefer';
        case dbTypes.ELASTICSEARCH:
          return 'https://<user>:<password>@' + hostname;
        case dbTypes.INFLUX:
          return 'https://<user>:<password>@' + hostname + '/' + resourceId;
      }
    }),

    consoleCommand: Ember.computed(function () {
      var typeName = this.get('typeName');
      switch (typeName) {
        case dbTypes.MONGO:
          return 'mongo-console';
        case dbTypes.MYSQL:
          return 'mysql-console';
        case dbTypes.PGSQL:
          return 'pgsql-console';
        case dbTypes.REDIS:
          return 'redis-console';
        case dbTypes.INFLUX:
          return 'influxdb-console';
      }
      return null;
    }),

    hasBackups: Ember.computed('backups', function (k, v) {
      var _this = this;

      if (arguments.length > 1) {
        return v;
      }
      this.get('backups').then(function (backups) {
        _this.set('hasBackups', backups.length > 0);
      });
      return v;
    }),

    nextVersionWarningMessage: Ember.computed('nextVersionWarning', 'isTimescaleDB', function () {
      if (this.get('isTimescaleDB')) {
        return "Please contact your account manager to upgrade your TimescaleDB database to a new version";
      }

      return this.get('nextVersionWarning');
    }),

    hasAvailableUpgrade: Ember.computed('nextVersion', function () {
      return this.get('nextVersion.content') != null;
    }),

    hasAdvancedFeatures: Ember.computed('typeName', function () {
      var advancedFeaturesDB = [dbTypes.MONGO, dbTypes.PGSQL, dbTypes.ELASTICSEARCH, dbTypes.INFLUX, dbTypes.REDIS, dbTypes.MYSQL];
      return advancedFeaturesDB.indexOf(this.get('typeName')) != -1;
    }),

    isSANDCluster: Ember.computed('typeName', 'cluster', function () {
      var t = this.get('typeName');
      return this.get('cluster') && (t === dbTypes.PGSQL || t === dbTypes.ELASTICSEARCH || t === dbTypes.REDIS || t === dbTypes.MYSQL);
    }),

    hasMongoIndex: Ember.computed('typeName', function () {
      return this.get('typeName') === dbTypes.MONGO;
    }),

    hasOplog: Ember.computed('features.[]', function () {
      return this.hasFeature(features.OPLOG);
    }),

    isPubliclyAvailable: Ember.computed('features.[]', function () {
      return this.hasFeature(features.PUBLICLY_AVAILABLE);
    }),

    isTimescaleDB: Ember.computed('postgresqlConfig', function () {
      return this.get('postgresqlConfig.timescaledb_enabled') === true;
    }),

    needDownloadableCaCert: Ember.computed('features.[]', function () {
      return this.get("version.hasTLS");
    }),

    caCertUrl: Ember.computed(function () {
      return window.EmberENV.apiBaseURI + '/ca_certificate';
    }),

    hasFeature: function hasFeature(feature) {
      if (!feature) {
        return false;
      }
      var features = this.get('features');
      if (!features) {
        return false;
      }

      return features.map(function (f) {
        return f.name;
      }).contains(feature);
    },
    featureStatus: function featureStatus(featureName) {
      if (!featureName) {
        return featurestatus.NOTFOUND;
      }

      var features = this.get('features');

      var feature = features.find(function (f) {
        return f.name == featureName;
      });
      if (!feature) {
        return featurestatus.NOTFOUND;
      }
      return feature.status;
    },
    reloadUntilFeatureIsProvisionned: function reloadUntilFeatureIsProvisionned(feature, duration) {
      var _this2 = this;

      setTimeout(function () {
        _this2.reload().then(function (db) {
          if (db.featureStatus(feature).startsWith(featurestatus.PENDING)) {
            db.reloadUntilFeatureIsProvisionned(feature, 10000);
          }
        }).catch(function () {
          // In some cases the DB will be unavailable during provisioning
          // and the reload will stop in a perfectly normal case.
          _this2.reloadUntilFeatureIsProvisionned(feature, 10000);
        });
      }, duration);
    },


    /*
     * feature is actually the name of the feature
     * options can be anything
     */
    addFeature: function addFeature(feature, options) {
      var _this3 = this;

      var adapter = this.store.adapterFor('database');
      var url = adapter.buildURL('database', this.get('id')) + '/features';
      return adapter.ajax(url, 'POST', {
        data: {
          feature: {
            name: feature,
            options: options
          }
        }
      }).then(function (data) {
        _this3.get('features').addObject({ name: data.name, status: data.status });
      });
    },


    /*
     * feature is actually the name of the feature
     */
    removeFeature: function removeFeature(featureName) {
      var _this4 = this;

      var adapter = this.store.adapterFor('database');
      var url = adapter.buildURL('database', this.get('id')) + '/features?feature=' + featureName;
      return adapter.ajax(url, 'DELETE', null).then(function () {
        _this4.get('features').forEach(function (feature) {
          if (feature.name == featureName) {
            _this4.get('features').removeObject(feature);
            return;
          }
        });
      });
    },


    /*
     * fetch plan of the database
     */
    currentPlan: Ember.computed('plan', function () {
      var _this5 = this;

      var adapter = this.store.adapterFor('database');
      var url = adapter.buildURL('database', this.get('id')) + '/plan';
      return adapter.ajax(url, 'GET').then(function (data) {
        _this5.store.pushPayload('plan', data);
        var modelPlan = _this5.store.peekRecord('plan', data.plan.id);
        return modelPlan;
      });
    }),

    logsURL: function logsURL() {
      var adapter = this.store.adapterFor('database');
      var url = adapter.buildURL('database', this.get('id')) + '/logs';
      return adapter.ajax(url, 'GET');
    },


    /*
     * Informations about logical databases of a database deployment
     */
    databases: function databases() {
      return this.action('list-databases', {}).then(function (res) {
        return res.result;
      });
    },
    deleteDatabase: function deleteDatabase(db) {
      return this.action('delete-database', { database_name: db }).then(function (res) {
        return res.result;
      });
    },
    createDatabase: function createDatabase(db) {
      return this.action('create-database', { database_name: db }).then(function (res) {
        return res.result;
      });
    },
    resetDatabase: function resetDatabase(db) {
      return this.action('reset-database', { database_name: db }).then(function (res) {
        return res.result;
      });
    },


    // SQLMode configuration
    sqlMode: function sqlMode() {
      return this.action('get-sqlmode', {}).then(function (res) {
        return res.result;
      });
    },
    setSQLMode: function setSQLMode(mode) {
      return this.action('set-sqlmode', mode).then(function (res) {
        return res.result;
      });
    },
    action: function action(actionName, params) {
      var adapter = this.store.adapterFor('database');
      var url = adapter.buildURL('database', this.get('id')) + '/action';
      var request = Ember.$.ajax({
        method: 'POST',
        url: url,
        contentType: 'application/json; charset=utf-8',
        processData: false,
        data: JSON.stringify({
          action_name: actionName,
          params: params
        }),
        dataType: 'json'
      });
      return new Ember.RSVP.Promise(function (resolve, reject) {
        request.done(function (data) {
          resolve(data);
        });
        request.fail(function (jqXHR) {
          reject(jqXHR.responseJSON, jqXHR);
        });
      });
    },
    upgrade: function upgrade() {
      var _this6 = this;

      var adapter = this.store.adapterFor('database');
      var url = adapter.buildURL('database', this.get('id')) + '/upgrade';

      var request = Ember.$.ajax({
        method: 'POST',
        url: url,
        contentType: 'application/json; charset=utf-8',
        dataType: 'json'
      });

      var requestUpgradePromise = new Ember.RSVP.Promise(function (resolve, reject) {
        request.done(function (data, _, jqXHR) {
          // For the getResponseHeader to work, the CORS headers sent by the server
          // need to expose Location
          resolve(jqXHR.getResponseHeader('Location'));
        });
        request.fail(function (jqXHR, textStatus) {
          reject(textStatus);
        });
      });

      var endOfUpgradePromise = requestUpgradePromise.then(function (operationUrl) {
        return _this6.monitorOperation(operationUrl);
      });

      return { triggered: requestUpgradePromise, done: endOfUpgradePromise };
    },
    monitorOperation: function monitorOperation(operationUrl) {
      var _this7 = this;

      var url = window.EmberENV.apiBaseURI + "/" + operationUrl;
      if (operationUrl.startsWith('http')) {
        url = operationUrl;
      }
      var previousStatus = null;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        // Get every checkInterval ms the upgrade operation and
        // wait for it to finish or error
        var upgradeIntervalId = setInterval(function () {
          var requestOperation = Ember.$.getJSON(url);
          requestOperation.done(function (data) {
            if (previousStatus !== null && previousStatus === data.operation.status) {
              return;
            }
            previousStatus = data.operation.status;
            _this7.store.pushPayload('operation', data);
            _this7.set('currentOperation', _this7.store.recordForId('operation', data.operation.id));
            var status = _this7.get('currentOperation.status');
            if (status === _operation.statuses.ERROR || status === _operation.statuses.FINISHED || status === _operation.statuses.USER_ERROR) {
              clearInterval(_this7.get('upgradeIntervalId'));
              if (status === _operation.statuses.ERROR) {
                reject(data.operation.error);
                return;
              }
              resolve(data.operation);
              return;
            }
          });
          requestOperation.fail(function (jqXHR, textStatus) {
            clearInterval(_this7.get('upgradeIntervalId'));
            _this7.set("currentOperation.error", textStatus);
            reject(textStatus);
          });
        }, _this7.get('checkInterval'));
        _this7.set('upgradeIntervalId', upgradeIntervalId);
      });
    },
    manualBackup: function manualBackup() {
      var adapter = this.store.adapterFor('database');
      var url = adapter.buildURL('database', this.get('id')) + '/backups';
      var request = Ember.$.ajax({
        method: 'POST',
        url: url,
        contentType: 'application/json; charset=utf-8',
        processData: false,
        dataType: 'json'
      });
      return new Ember.RSVP.Promise(function (resolve, reject) {
        request.done(resolve);
        request.fail(reject);
      });
    },
    pitrRestore: function pitrRestore(restoreTime) {
      var adapter = this.store.adapterFor('database');
      var url = adapter.buildURL('database', this.get('id')) + '/pitr/restore';
      var request = Ember.$.ajax({
        method: 'POST',
        url: url,
        contentType: 'application/json',
        data: JSON.stringify({ restore_time: restoreTime })
      });
      return new Ember.RSVP.Promise(function (resolve, reject) {
        request.done(resolve);
        request.fail(reject);
      });
    },


    canModifyUsers: Ember.computed('typeName', function () {
      var typeName = this.get('typeName');
      return typeName === "mongo" || typeName === "mysql" || typeName === "postgresql" || typeName === "influxdb";
    }),

    canModifyDatabases: Ember.computed('typeName', function () {
      var typeName = this.get('typeName');
      return typeName === "mysql" || typeName === "mongo";
    }),

    showDatabaseSize: Ember.computed('typeName', function () {
      var typeName = this.get('typeName');
      return !(typeName === dbTypes.REDIS || typeName === dbTypes.ELASTICSEARCH);
    }),

    showConnections: Ember.computed('typeName', function () {
      var typeName = this.get('typeName');
      return typeName === dbTypes.MYSQL || typeName === dbTypes.PGSQL;
    }),

    themeColor: Ember.computed('typeName', function () {
      var typeName = this.get('typeName');

      switch (typeName) {
        case dbTypes.MONGO:
          return 'mongo-color';
        case dbTypes.MYSQL:
          return 'mysql-color';
        case dbTypes.PGSQL:
          return 'postgresql-color';
        case dbTypes.REDIS:
          return 'redis-color';
        case dbTypes.ELASTICSEARCH:
          return 'elasticsearch-color';
        case dbTypes.INFLUX:
          return 'influx-color';
        default:
          return '';
      }
    }),

    displayName: Ember.computed('typeName', function () {
      var typeName = this.get('typeName');

      switch (typeName) {
        case dbTypes.MONGO:
          return 'MongoDB';
        case dbTypes.MYSQL:
          return 'MySQL';
        case dbTypes.PGSQL:
          return 'PostgreSQL';
        case dbTypes.REDIS:
          return 'Redis';
        case dbTypes.ELASTICSEARCH:
          return 'Elasticsearch';
        case dbTypes.INFLUX:
          return "InfluxDB";
        default:
          return '';
      }
    }),

    environmentVariableName: Ember.computed('typeName', function () {
      var typeName = this.get('typeName');

      switch (typeName) {
        case dbTypes.MONGO:
          return 'SCALINGO_MONGO_URL';
        case dbTypes.MYSQL:
          return 'SCALINGO_MYSQL_URL';
        case dbTypes.PGSQL:
          return 'SCALINGO_POSTGRESQL_URL';
        case dbTypes.REDIS:
          return 'SCALINGO_REDIS_URL';
        case dbTypes.ELASTICSEARCH:
          return 'SCALINGO_ELASTICSEARCH_URL';
        case dbTypes.INFLUX:
          return 'SCALINGO_INFLUX_URL';
        default:
          return '';
      }
    }),

    minVersionForTLS: Ember.computed('typeName', function () {
      var typeName = this.get('typeName');

      switch (typeName) {
        case dbTypes.MONGO:
          return '3.4.7';
        case dbTypes.MYSQL:
          return '5.7.19';
        case dbTypes.PGSQL:
          return '9.6.5';
        case dbTypes.ELASTICSEARCH:
          return '5.5.3';
        case dbTypes.INFLUX:
          return '1.2.4';
        default:
          return '';
      }
    }),

    dockerHubUrl: Ember.computed('typeName', function () {
      return 'https://hub.docker.com/r/scalingo/' + this.get('typeName');
    })
  });
});