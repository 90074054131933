define('database-dashboard/models/instance', ['exports', 'ember-data', 'database-dashboard/models/database'], function (exports, _emberData, _database) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.instanceTypes = undefined;
  var instanceTypes = exports.instanceTypes = {
    GATEWAY: "gateway",
    DB_NODE: "db-node",
    UTILITY: "utility"
  };

  exports.default = _emberData.default.Model.extend({
    hostname: _emberData.default.attr('string'),
    port: _emberData.default.attr('number'),
    status: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    privateIp: _emberData.default.attr('string'),
    features: _emberData.default.attr(),

    // Added relationships
    database: _emberData.default.belongsTo('database'),

    typeString: Ember.computed('type', function () {
      switch (this.get('type')) {
        case instanceTypes.GATEWAY:
          return 'Gateway';
        case instanceTypes.DB_NODE:
          return 'Node';
        case instanceTypes.UTILITY:
          if (this.get('database.typeName') == _database.dbTypes.REDIS) {
            return 'Sentinel';
          }
          return 'Utility';
        default:
          return 'Unknown';
      }
    }),

    hasFeature: function hasFeature(featureName) {
      if (!featureName) {
        return false;
      }
      var features = this.get('features');
      if (!features) {
        return false;
      }

      return features.contains(featureName);
    }
  });
});