define('database-dashboard/components/sc-chart', ['exports', 'database-dashboard/components/c3-chart'], function (exports, _c3Chart) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _c3Chart.default.extend({
    tooltipPos: null,
    reload: null,
    lastIndex: 0,
    lastValue: 0,

    findClosest: function findClosest(x) {
      var closest, closestIndex, data, i, incr, index, j, lastIndex, lastValue, max, min, ref, value;
      lastIndex = this.get("lastIndex");
      lastValue = this.get("lastValue");
      incr = 1;
      data = this._chart.data()[0].values;
      max = data.length;
      if (lastIndex <= 0 || lastIndex > data.length) {
        lastValue = data[0].value;
        lastIndex = 0;
      }
      if (lastValue > x) {
        incr = -1;
        max = lastIndex;
      } else {
        max = data.length - lastIndex;
      }
      closest = lastValue;
      closestIndex = lastIndex;
      min = Math.abs(closest - x);
      for (i = j = 0, ref = max; 0 <= ref ? j <= ref : j >= ref; i = 0 <= ref ? ++j : --j) {
        index = lastIndex + i * incr;
        value = data[index];
        if (value && Math.abs(value.x - x) <= min) {
          closest = value.x;
          closestIndex = index;
          min = Math.abs(value.x - x);
        } else {
          break;
        }
      }
      this.set("lastValue", closest);
      this.set("lastIndex", closestIndex);
      return closest;
    },

    toolTipObserver: Ember.observer('tooltipPos', function () {
      var args, tooltip;
      tooltip = this.get("tooltipPos");
      if (tooltip === null) {
        this.set("lastIndex", 0);
        this.set("lastValue", 0);
        return this._chart.tooltip.hide();
      } else {
        args = {
          x: this.findClosest(tooltip)
        };
        return this._chart.tooltip.show(args);
      }
    }),

    reloadObserver: Ember.observer('reload', function () {
      var _this = this;

      return setTimeout(function () {
        var axis = _this.get("axis");
        _this._chart.axis.range({
          min: {
            x: axis.x.min,
            y: axis.y.min
          },
          max: {
            x: axis.x.max,
            y: axis.y.max
          }
        });
        var grid = _this.get("grid");
        if (grid !== null && grid.y !== null) {
          _this._chart.ygrids(grid.y.lines);
        }
        _this._chart.load(_this.get("data"));
        return _this._chart.flush();
      }, 100);
    })
  });
});