define('database-dashboard/components/single-chart', ['exports', 'database-dashboard/templates/components/single-chart', 'moment'], function (exports, _singleChart, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _singleChart.default,
    tooltipPos: null,
    dataTypes: null,
    dataObjects: null,
    dataURLS: null,
    tooltipUpdate: null,
    onRendered: null,
    since: null,
    customColors: null,
    customLegend: false,
    timezone: _moment.default.tz.guess(),
    autoupdate: true,
    loaded: false,
    tempData: null,
    failed: false,
    internalError: false,
    addData: null,
    reload: null,
    intervalId: null,
    data: null,
    size: null,
    padding: null,
    legend: {
      show: false
    },
    grid: null,
    color: {
      pattern: ["#03A9F4", "#77D3FE"]
    },
    point: null,
    tooltip: {
      position: null
    },
    tooltipTitleBuilder: function tooltipTitleBuilder() {
      var _this = this;

      return function (x) {
        return (0, _moment.default)(x).tz(_this.get("timezone")).format("DD/MM HH:mm");
      };
    },
    dataURLSObserver: Ember.observer('dataURLS', 'dataObjects', function () {
      this.updateGraph(0, null, false);
    }),
    timezoneObserver: Ember.observer('timezone', function () {
      this.set("reload", Math.random());
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.updateGraph(0, null, false);
    },
    willDestroyElement: function willDestroyElement() {
      var intervalId;
      intervalId = this.get("intervalId");
      if (intervalId !== null) {
        clearInterval(intervalId);
      }
    },
    initGraphData: function initGraphData() {
      var _this2 = this;

      var padding = {
        top: 0,
        right: 0,
        bottom: 0
      };
      var tooltip = {
        format: {
          title: this.tooltipTitleBuilder()
        },
        onhide: function onhide() {
          _this2.get("tooltipUpdate")(null);
        }
      };
      var grid = {
        y: {
          show: true
        }
      };
      var size = {
        height: 150
      };
      if (this.get("dataTypes")[0] === "events") {
        size.height = 100;
      }
      this.set("internalError", false);
      this.set("tooltipValues", []);
      this.set("size", size);
      this.set("grid", grid);
      this.set("tooltip", tooltip);
      this.set("padding", padding);
    },

    updateGraph: function updateGraph(dataIndex, data, successfull) {
      var _this3 = this;

      this.set("loaded", false);
      this.set("failed", false);
      var dataURLS = this.get("dataURLS");
      var dataObjects = this.get("dataObjects");
      if (dataObjects === null && dataURLS === null) {
        return;
      }
      var dataTypes = this.get("dataTypes");
      if (dataIndex === 0) {
        this.initGraphData();
      }
      if (dataIndex === dataTypes.length) {
        if (successfull) {
          var mainObj = this;
          setTimeout(function () {
            mainObj.propertyWillChange("data");
            mainObj.set("data", data);
            mainObj.propertyDidChange("data");
            mainObj.set("loaded", true);
            if (mainObj.get("autoupdate")) {
              var updateInterval = mainObj.getUpdateInterval();
              if (updateInterval > 0) {
                var intervalId = setInterval(mainObj.updaterBuilder(), updateInterval);
                mainObj.set("intervalId", intervalId);
              }
            }
          }, 100);
        } else {
          var _mainObj = this;
          setTimeout(function () {
            _mainObj.renderDummy();
          }, 100);
        }
      } else {
        if (dataTypes[dataIndex] === "events") {
          if (dataObjects[dataIndex].length === 0) {
            this.updateGraph(dataIndex + 1, data, successfull);
          } else {
            var result = {
              data: dataObjects[dataIndex],
              max: 0,
              type: dataTypes[dataIndex]
            };
            this.formatMetrics(result, function (data) {
              _this3.updateGraph(dataIndex + 1, data, true);
            });
          }
        } else {
          this.getMetrics(dataTypes[dataIndex], dataURLS[dataIndex]).then(function (data) {
            _this3.updateGraph(dataIndex + 1, data, true);
          }, function (status) {
            var internalError = _this3.get("internalError");
            internalError = internalError || status !== 404;
            _this3.set("internalError", internalError);
            _this3.updateGraph(dataIndex + 1, data, successfull);
          });
        }
      }
    },
    formatValues: function formatValues(values, type) {
      var i, j, len, result, sample;
      result = {
        data: [[type], ['x']],
        max: 0,
        type: type
      };
      for (i = j = 0, len = values.length; j < len; i = ++j) {
        sample = values[i];
        if (sample.value === null && i > 0) {
          sample.value = values[i - 1].value;
        }
        sample.value = sample.value || 0;
        if (sample.value === 0 && i <= 1) {
          continue;
        }
        if (sample.value === 0 && i >= values.length - 2) {
          continue;
        }
        if (sample.value > result.max) {
          result.max = sample.value;
        }
        result.data[0].push(sample.value);
        result.data[1].push((0, _moment.default)(sample.time).toDate());
      }
      return result;
    },
    getMetrics: function getMetrics(type, uri) {
      var _this4 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        return Ember.$.getJSON(uri, function (val) {
          var result = _this4.formatValues(val, type);
          return _this4.formatMetrics(result, resolve, reject);
        }).fail(function (xhr) {
          return reject(xhr.status);
        });
      });
    },
    renderDummy: function renderDummy() {
      var result;
      result = {
        data: [["abc", 0, 0], ['x', (0, _moment.default)().add(2, 'hour').valueOf(), (0, _moment.default)().add(1, 'hour').valueOf()]],
        max: 2,
        type: "dummy"
      };
      this.formatMetrics(result, this.dummyLoaded());
    },
    dummyLoaded: function dummyLoaded() {
      var _this5 = this;

      return function (data) {
        _this5.propertyWillChange("data");
        _this5.set("data", data);
        _this5.propertyDidChange("data");
        _this5.set("loaded", true);
        _this5.set("failed", true);
      };
    },
    formatMetrics: function formatMetrics(metrics, resolve) {
      metrics = this.preFormat(metrics);
      var data = metrics.data;
      var max = metrics.max;
      var type = metrics.type;
      var tooltip = this.get("tooltip");
      var axis = this.get("axis");
      if (type === "dummy") {
        tooltip.show = false;
      }
      this.formatXAxis(axis, data, type);
      this.formatOptions(max);
      if (type === "dummy") {
        this.formatDummyOptions();
        data = this.formatDummyData(data);
        axis = this.formatDummyAxis(axis, max);
        this.formatDummyGrid(axis, max);
      } else {
        data = this.formatData(data);
        axis = this.formatAxis(axis, max, type);
        tooltip = this.formatTooltip(tooltip);
        this.formatGrid(axis, max, type);
      }
      this.propertyWillChange("axis");
      this.set("axis", axis);
      this.propertyDidChange("axis");
      this.propertyWillChange("tooltip");
      this.set("tooltip", tooltip);
      this.propertyDidChange("tooltip");
      this.set("tempData", data);
      resolve(data);
    },
    preFormat: function preFormat(metrics) {
      return metrics;
    },

    formatXAxis: function formatXAxis(axis, data, type) {
      var diff, i, incr, j, ref, since, tickCount, xEnd, xStart;
      since = this.get("since");
      if (type === "dummy") {
        xStart = (0, _moment.default)().startOf('hour').subtract(since, 'hour');
        xEnd = (0, _moment.default)().startOf('hour');
        axis.x.min = (0, _moment.default)().subtract(since, 'hour');
        axis.x.max = (0, _moment.default)();
      } else {
        xStart = (0, _moment.default)(data[1][1]).startOf('hour');
        xEnd = (0, _moment.default)(data[1][data[1].length - 1]).startOf('hour');
      }
      diff = xEnd.diff(xStart, 'hours');
      tickCount = 6;
      incr = Math.floor(diff / 6);
      if (incr === 0) {
        incr = 1;
        tickCount = 4;
      }
      for (i = j = 1, ref = tickCount - 1; 1 <= ref ? j <= ref : j >= ref; i <= ref ? ++j : --j) {
        axis.x.tick.values.push(xStart.add(incr, 'hour').valueOf());
      }
      axis.x.tick.format = this.tickFormatBuilder();
    },

    tickFormatBuilder: function tickFormatBuilder() {
      var _this6 = this;

      return function (d) {
        return (0, _moment.default)(d).tz(_this6.get("timezone")).format("HH:mm");
      };
    },

    formatAxis: function formatAxis(axis, max) {
      axis.y.max = max;
      return axis;
    },
    formatDummyOptions: function formatDummyOptions() {
      var padding;
      padding = this.get("padding");
      padding.left = 50;
      this.set("padding", padding);
    },
    formatDummyAxis: function formatDummyAxis(axis) {
      axis.y.show = false;
      return axis;
    },
    formatDummyGrid: function formatDummyGrid() {
      var grid;
      grid = this.get("grid");
      grid.y.show = false;
      this.propertyWillChange("grid");
      this.set("grid", grid);
      this.propertyDidChange("grid");
    },
    formatDummyData: function formatDummyData(metricsData) {
      var data;
      data = {
        hide: true,
        columns: metricsData,
        x: 'x',
        types: {}
      };
      return data;
    },
    formatData: function formatData(metricsData, type) {
      var customColors, data, i, j, ref;
      data = {
        columns: metricsData,
        x: 'x',
        onmouseover: this.dataMouseoverBuilder(),
        types: {}
      };
      customColors = this.get("customColors");
      if (customColors != null) {
        data.colors = customColors;
      }
      for (i = j = 0, ref = metricsData.length - 1; 0 <= ref ? j <= ref : j >= ref; i = 0 <= ref ? ++j : --j) {
        if (metricsData[i][0] !== 'x') {
          data.types[metricsData[i][0]] = type || "area";
        }
      }
      return data;
    },
    formatGrid: function formatGrid() {},
    formatOptions: function formatOptions() {
      var pointValue;
      pointValue = {
        show: false
      };
      this.set("point", pointValue);
    },
    formatTooltip: function formatTooltip(tooltip) {
      tooltip.position = this.positionBuilder();
      return tooltip;
    },
    dataMouseoverBuilder: function dataMouseoverBuilder() {
      var _this7 = this;

      return function (e) {
        return _this7.get("tooltipUpdate")(e.x);
      };
    },
    positionBuilder: function positionBuilder() {
      var _this8 = this;

      return function (data, width, height, element) {
        var graphContainer = document.querySelector("#" + _this8.elementId);
        var chartOffsetX = graphContainer.getBoundingClientRect().left;
        var graphOffsetX = graphContainer.querySelector(".c3-axis-y").getBoundingClientRect().right;
        var x = parseInt(element.getAttribute('x')) + graphOffsetX - chartOffsetX - width;
        return {
          top: 0,
          left: x
        };
      };
    },

    getUpdateInterval: function getUpdateInterval() {
      var column, data, j, len, ref;
      data = this.get("data");
      ref = data.columns;
      for (j = 0, len = ref.length; j < len; j++) {
        column = ref[j];
        if (column[0] === 'x') {
          return (0, _moment.default)(column[2]).diff(column[1]);
        }
      }
      return -1;
    },
    getDataIndex: function getDataIndex(data, type) {
      var index, j, len, value;
      for (index = j = 0, len = data.length; j < len; index = ++j) {
        value = data[index];
        if (value[0] === type) {
          return index;
        }
      }
      return -1;
    },
    updaterBuilder: function updaterBuilder() {
      var _this9 = this;

      return function () {
        var index, j, len;
        var dataURLS = _this9.get("dataURLS");
        var types = _this9.get("dataTypes");
        if (dataURLS === null) {
          return null;
        }

        var promises = [];
        for (index = j = 0, len = dataURLS.length; j < len; index = ++j) {
          var url = dataURLS[index];
          if (url !== null) {
            promises.push(_this9.updateWorker(url + "&last=true", types[index]));
          }
        }
        return Ember.RSVP.all(promises).then(function (results) {
          var axis, data;
          data = _this9.get("data");
          axis = _this9.get("axis");
          data = _this9.autoUpdateFormater(data, results);
          _this9.autoUpdateXAxisFormatter(axis, data);
          _this9.autoUpdateMaxFormatter(axis, data);
          _this9.propertyWillChange("data");
          _this9.set("data", data);
          _this9.propertyDidChange("data");
          _this9.set("axis", axis);
          return _this9.set("reload", Math.random());
        });
      };
    },
    autoUpdateFormater: function autoUpdateFormater(data, results) {
      var dataIndex, j, len, result, x, xDataIndex;
      x = false;
      for (j = 0, len = results.length; j < len; j++) {
        result = results[j];
        dataIndex = this.getDataIndex(data.columns, result.type);
        if (dataIndex !== -1) {
          if (result.metrics) {
            data.columns[dataIndex].push(result.metrics[0].value);
            if (!x) {
              x = true;
              xDataIndex = this.getDataIndex(data.columns, 'x');
              data.columns[xDataIndex].push((0, _moment.default)(result.metrics[0].time).toDate());
              data.columns[xDataIndex].splice(1, 1);
            }
          } else {
            data.columns[dataIndex].push(null);
          }
          data.columns[dataIndex].splice(1, 1);
        }
      }
      return data;
    },
    autoUpdateXAxisFormatter: function autoUpdateXAxisFormatter(axis, data) {
      axis.x.tick.values = [];
      return this.formatXAxis(axis, data.columns, "");
    },
    autoUpdateMaxFormatter: function autoUpdateMaxFormatter(axis, data) {
      var column, j, k, len, len1, max, ref, value;
      max = axis.y.max;
      ref = data.columns;
      for (j = 0, len = ref.length; j < len; j++) {
        column = ref[j];
        if (column[0] === 'x') {
          continue;
        }
        for (k = 0, len1 = column.length; k < len1; k++) {
          value = column[k];
          if (value > max) {
            max = value;
          }
        }
      }
      axis.y.max = max;
    },
    updateWorker: function updateWorker(url, type) {
      return new Ember.RSVP.Promise(function (resolve) {
        return Ember.$.getJSON(url, function (val) {
          return resolve({
            metrics: val,
            type: type
          });
        }).fail(function () {
          return resolve({
            metrics: null,
            type: type
          });
        });
      });
    }
  });
});