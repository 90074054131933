define('database-dashboard/models/operation', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.types = exports.statuses = undefined;
  var statuses = exports.statuses = {
    PENDING: "pending",
    RUNNING: "running",
    FINISHED: "finished",
    ERROR: "error",
    USER_ERROR: "user-error"
  };

  var types = exports.types = {
    UPGRADE: "upgrade",
    PITR_BACKUP: "pitr_backup",
    PITR_RESTORE: "pitr_restore"
  };

  exports.default = _emberData.default.Model.extend({
    status: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    finishedAt: _emberData.default.attr('date'),
    type: _emberData.default.attr('string'),
    error: _emberData.default.attr('string'),

    // Added relationships
    database: _emberData.default.belongsTo('database'),

    isUpgrading: Ember.computed('status', function () {
      var status = this.get('status');
      return status === statuses.PENDING || status === statuses.RUNNING;
    })
  });
});