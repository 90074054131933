define('database-dashboard/components/help-dropdown', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var HelpDropdownComponent = Ember.Component.extend({
    tagName: 'a',
    classNames: ['dropdown-button', 'navbar-menu', 'navbar-help-menu'],
    attributeBindings: ['inDuration', 'outDuration', 'constrainWidth', 'hover', 'gutter', 'belowOrigin'],

    belowOrigin: true,
    constrainWidth: false,

    didInsertElement: function didInsertElement() {
      this._super(this, arguments);
      Ember.run.scheduleOnce('afterRender', this, this._setupDropdown);
    },

    _setupDropdown: function _setupDropdown() {
      // needed until the Materialize.dropdown plugin is replaced
      this.$().attr('data-activates', this.get('_dropdownContentId'));

      this.$().dropdown2({
        hover: !!this.getWithDefault('hover', false),
        constrain_width: !!this.getWithDefault('constrainWidth', true),
        inDuration: this.getWithDefault('inDuration', this.get('_mdSettings.dropdownInDuration')),
        outDuration: this.getWithDefault('outDuration', this.get('_mdSettings.dropdownOutDuration')),
        gutter: this.getWithDefault('gutter', 0),
        belowOrigin: !!this.getWithDefault('belowOrigin', false)
      });
    },

    _dropdownContentId: Ember.computed('elementId', function () {
      return this.get('elementId') + "-dropdown-content";
    })
  });

  exports.default = HelpDropdownComponent;
});