define('database-dashboard/components/detail-layout', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DetailLayoutComponent = Ember.Component.extend({
    endLoading: 'addLayoutOffset',

    willInsertElement: function willInsertElement() {
      this.sendAction('endLoading');
    }
  });

  exports.default = DetailLayoutComponent;
});