define('database-dashboard/routes/dashboard/pg-running-queries', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Current Queries',

    model: function model() {
      var db = this.modelFor('dashboard').db;

      return Ember.RSVP.hash({
        db: db
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('dashboard.pg-running-queries').send('reload');
    },


    actions: {
      didTransition: function didTransition() {
        this.send('updateSelectedTab', 'pg-running-queries');
      }
    }
  });
});