define('database-dashboard/components/sc-md-toggle-btn', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    text: null,
    elementId: null,
    selectedId: null,
    action: null,
    actions: {
      click: function click() {
        if (this.get("selectedId") !== this.get("elementId")) {
          return this.get('action')(this.get("elementId"));
        }
      }
    },
    isSelected: Ember.computed('elementId', 'selectedId', function () {
      return this.get("selectedId") === this.get("elementId");
    })
  });
});