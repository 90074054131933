define('database-dashboard/components/overview-right', ['exports', 'database-dashboard/models/database'], function (exports, _database) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OverviewRightComponent = Ember.Component.extend({
    // Injected
    db: null,
    dbMetrics: null,
    dbHealth: null,
    dbLag: null,
    plan: null,

    nbDBNodes: Ember.computed('db.typeName', function () {
      switch (this.get('db.typeName')) {
        case _database.dbTypes.ELASTICSEARCH:
          return this.get('plan.esMasterDataNodes');
        case _database.dbTypes.MYSQL:
          return this.get('plan.mysqlNodes');
        case _database.dbTypes.PGSQL:
          return this.get('plan.postgresqlNodes');
        case _database.dbTypes.REDIS:
          return this.get('plan.redisNodes');
        default:
          return -1;
      }
    }),

    healthStatusString: Ember.computed('dbHealth.status', function () {
      switch (this.get('dbHealth.status')) {
        case 'green':
          return 'Green - Healthy';
        case 'yellow':
          return 'Yellow - Replication warning';
        case 'red':
          return 'Red - Unhealthy';
        default:
          return 'Unknown';
      }
    }),
    instanceSortProperties: ['type:desc', 'privateIp:asc'],
    sortedInstances: Ember.computed.sort('db.instances', 'instanceSortProperties')
  });

  exports.default = OverviewRightComponent;
});