define('database-dashboard/adapters/database-type-version', ['exports', 'database-dashboard/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DatabaseTypeVersionAdapter = _application.default.extend({});

  exports.default = DatabaseTypeVersionAdapter;
});