define('database-dashboard/controllers/dashboard/pg-running-queries', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // injected
    db: Ember.computed.alias('model.db'),

    // HTML
    classNames: ['row'],

    // Internals
    modalAction: '',
    modalQuery: '',
    modalPid: '',
    modalIsOpen: false,

    showIdleQueries: false,

    runningQueries: null,
    isRefreshing: null,

    userQueries: Ember.computed.filter('runningQueries', function (query) {
      var adminUsernames = ['admin', 'admin_patroni', 'replicator'];
      return !adminUsernames.includes(query.username);
    }),
    idleQueries: Ember.computed.filter('userQueries', function (query) {
      return query.state === 'idle';
    }),
    activeQueries: Ember.computed.filter('userQueries', function (query) {
      return query.state !== 'idle';
    }),
    displayedQueries: Ember.computed('showIdleQueries', 'userQueries', 'activeQueries', function () {
      if (this.get('showIdleQueries')) {
        return this.get('userQueries');
      }
      return this.get('activeQueries');
    }),

    showIdleQueriesObs: Ember.observer('showIdleQueries', function () {
      this.send('reload');
    }),

    showIdleQueriesLabel: Ember.computed('showIdleQueries', 'idleQueries', function () {
      var verb = 'Show';
      if (this.get('showIdleQueries')) {
        verb = 'Hide';
      }

      var count = this.get('idleQueries.length');
      if (count <= 0) {
        return verb + ' ' + count + ' idle querie:';
      } else {
        return verb + ' ' + count + ' idle queries:';
      }
    }),

    actions: {
      reload: function reload() {
        var _this = this;

        this.set('isRefreshing', true);
        this.get('db').action('pg-list-queries').then(function (action) {
          _this.set('runningQueries', action.result);
        }).finally(function () {
          _this.set('isRefreshing', null);
        });
      },
      cancelQuery: function cancelQuery(query) {
        this.set('modalAction', 'cancel');
        this.set('modalQuery', query.query);
        this.set('modalPid', query.pid);
        this.set('modalIsOpen', true);
      },
      terminateQuery: function terminateQuery(query) {
        this.set('modalAction', 'terminate');
        this.set('modalQuery', query.query);
        this.set('modalPid', query.pid);
        this.set('modalIsOpen', true);
      },
      modalAccept: function modalAccept() {
        var _this2 = this;

        var action = null;
        var pid = this.get('modalPid');
        if (this.get('modalAction') == 'cancel') {
          action = this.get('db').action('pg-cancel-query', { 'pid': pid });
        } else {
          action = this.get('db').action('pg-terminate-query', { 'pid': pid });
        }
        action.then().finally(function () {
          _this2.send('reload');
          _this2.send('closeModal');
        });
      },
      closeModal: function closeModal() {
        this.set('modalIsOpen', false);
      }
    }
  });
});