define('database-dashboard/adapters/database-backup', ['exports', 'database-dashboard/adapters/application', 'ember-data-url-templates'], function (exports, _application, _emberDataUrlTemplates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberDataUrlTemplates.default, {
    urlTemplate: '{+host}/backups{/id}',
    findAllUrlTemplate: '{+host}/databases/{dbId}/backups',
    queryUrlTemplate: '{+host}/databases/{dbId}/backups{?query*}',

    urlSegments: {
      id: function id(type, _id) {
        return _id;
      },
      dbId: function dbId(type, id, snapshot, query) {
        return query.dbId;
      }
    }

  });
});