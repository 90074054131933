define("database-dashboard/components/advanced-postgresql", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AdvancedPostgresqlComponent = Ember.Component.extend({
    // Injected
    db: null,

    // Internal
    extensionsList: ["btree_gist", "citext", "cube", "dblink", "dict_int", "earthdistance", "fuzzystrmatch", "hstore", " intarray", "isn", "ltree", "pg_repack", "pg_stat_statements", "pg_trgm", "pgcrypto", "pgrowlocks", "plpgsql", "postgis", "postgis_tiger_geocoder", "postgis_topology", "postgres_fdw", "tablefunc", "unaccent", "uuid-ossp"],

    enabledExtension: null
  });

  exports.default = AdvancedPostgresqlComponent;
});