define('database-dashboard/helpers/to-percent', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var percent = parseFloat(params[0]);
    return percent + ' %';
  });
});