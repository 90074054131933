define('database-dashboard/components/database-download-certificates', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DownloadCertificatesComponent = Ember.Component.extend({
    // Injected
    db: null
  });

  exports.default = DownloadCertificatesComponent;
});