define('database-dashboard/controllers/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    appName: '',
    dbType: '',
    navTitle: '',
    navColor: '',
    selectedTab: '',
    tabsContent: null,

    selectedTabObserver: Ember.observer('selectedTab', function () {
      if (this.get('selectedTab')) {
        this.transitionToRoute('dashboard.' + this.get('selectedTab'));
      }
    })
  });
});