define('database-dashboard/routes/dashboard/users', ['exports', 'database-dashboard/mixins/retry-until-success'], function (exports, _retryUntilSuccess) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_retryUntilSuccess.default, {
    titleToken: 'Users',

    model: function model() {
      return Ember.RSVP.hash({
        db: this.modelFor("dashboard")["db"]
      });
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      model.promise = this.retryUntilSuccess("database-users", function () {
        return _this.get('store').query('database-user', { dbId: model.db.get('id') }).then(function (users) {
          return users.forEach(function (user) {
            user.set('database', model.db);
          });
        });
      });
      controller.setProperties(model);
    },


    actions: {
      didTransition: function didTransition() {
        this.send('updateSelectedTab', 'users');
      }
    }
  });
});