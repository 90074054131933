define('database-dashboard/models/database-health', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * DatabaseHealth structure. Its content is an JSON:
   *
   * {
   *   cluster: string,
   *   status: string,
   *   shards: int,
   *   pending_tasks: int,
   * }
   *
   */
  var DatabaseHealth = Ember.Object.extend({});

  DatabaseHealth.reopenClass({
    get: function get(dbId) {
      var url = window.EmberENV.apiBaseURI + '/databases/' + dbId + '/health';
      return Ember.$.ajax({ url: url });
    }
  });

  exports.default = DatabaseHealth;
});