define('database-dashboard/controllers/dashboard/backups', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    backupReloadInterval: 10000,

    reloadBackup: function reloadBackup(backup) {
      var _this = this;

      return function () {
        backup.reload().then(function (backup) {
          if (backup.get('shouldBeReloaded')) {
            setTimeout(_this.reloadBackup(backup), _this.backupReloadInterval);
          }
        });
      };
    },

    dbBackupsObserver: Ember.observer('model.dbBackups', function () {
      var _this2 = this;

      var backups = this.get('model.dbBackups');
      if (Ember.isEmpty(backups)) {
        return;
      }
      backups.forEach(function (backup) {
        if (backup.get('shouldBeReloaded')) {
          setTimeout(_this2.reloadBackup(backup), _this2.backupReloadInterval);
        }
      });
    }),

    timezone: _moment.default.tz.guess(),

    actions: {
      reloadBackups: function reloadBackups() {
        var _this3 = this;

        this.store.unloadAll('databaseBackup');
        this.store.query('databaseBackup', { dbId: this.get('model.db.id') }).then(function (backups) {
          _this3.set('model.dbBackups', backups);
        });
      }
    }
  });
});