define('database-dashboard/helpers/to-utc', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (hour, tz) {
    var offset = _moment.default.tz.zone(tz).utcOffset((0, _moment.default)()) / 60;
    var utcTime = (hour + offset) % 24;
    if (utcTime < 0) {
      utcTime += 24;
    }
    return utcTime;
  };
});