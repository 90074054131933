define('database-dashboard/models/session', ['exports', 'js-base64', 'ember-cli-js-cookie'], function (exports, _jsBase, _emberCliJsCookie) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ACCESS_TOKEN = 'access_token';
  var DATABASE_ID = 'database_id';
  var IS_ADMIN = 'is_admin';

  var cookiesOpts = { path: '/' };

  exports.default = Ember.Object.extend({
    // Properties
    token: null,
    databaseId: null,
    isAdmin: false,

    initListener: Ember.on('init', function () {
      var token = _emberCliJsCookie.default.get(ACCESS_TOKEN);
      if (Ember.isPresent(token)) {
        this.setProperties({
          token: token,
          databaseId: _emberCliJsCookie.default.get(DATABASE_ID),
          isAdmin: _emberCliJsCookie.default.get(IS_ADMIN)
        });
      }
    }),

    authenticated: Ember.computed('token', function () {
      return Ember.isPresent(this.get('token'));
    }),

    httpToken: Ember.computed('token', function () {
      return 'Basic ' + _jsBase.Base64.encode(":" + this.get('token'));
    }),

    // Observers
    tokenChanged: Ember.observer('token', function () {
      if (this.get('authenticated')) {
        Ember.$.ajaxSetup({
          headers: { 'Authorization': this.get("httpToken") },
          dataType: 'json'
        });

        _emberCliJsCookie.default.set(ACCESS_TOKEN, this.get('token'), cookiesOpts);
      } else {
        Ember.$.ajaxSetup({ headers: { 'Authorization': 'Basic none' } });

        _emberCliJsCookie.default.remove(ACCESS_TOKEN, cookiesOpts);
      }
    }),

    databaseIdChanged: Ember.observer('databaseId', function () {
      var value = this.get('databaseId');
      if (value) {
        _emberCliJsCookie.default.set(DATABASE_ID, value, cookiesOpts);
      } else {
        _emberCliJsCookie.default.remove(DATABASE_ID, cookiesOpts);
      }
    }),

    isAdminChanged: Ember.observer('isAdmin', function () {
      var value = this.get('isAdmin');
      if (value) {
        _emberCliJsCookie.default.set(IS_ADMIN, value, cookiesOpts);
      } else {
        _emberCliJsCookie.default.remove(IS_ADMIN, cookiesOpts);
      }
    }),

    clear: function clear() {
      this.setProperties({
        token: null,
        databaseId: null,
        isAdmin: false
      });
    }
  });
});