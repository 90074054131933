define("database-dashboard/components/sc-md-tab", ["exports", "database-dashboard/components/md-tab"], function (exports, _mdTab) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ScMdTab = _mdTab.default.extend({
    // Override
    classNames: ["tab-flex"],
    _colClass: ""
  });

  exports.default = ScMdTab;
});