define('database-dashboard/mixins/postgresql-database-mixin', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    refreshExtensionsTrigger: null,

    pgExtensions: Ember.computed("refreshExtensionsTrigger", function () {
      return _emberData.default.PromiseArray.create({
        promise: this.action("list-extensions").then(function (action) {
          return action.result;
        })
      });
    }),

    refreshExtensionsList: function refreshExtensionsList() {
      this.set("refreshExtensionsTrigger", "refresh");
      return this.get('pgExtensions');
    },


    hasPgStatStatements: Ember.computed('pgExtensions', function () {
      return _emberData.default.PromiseObject.create({
        promise: this.get('pgExtensions').then(function (exts) {
          if (!exts) {
            return false;
          }
          return exts.filter(function (e) {
            return e.name == "pg_stat_statements";
          }).length == 1;
        })
      });
    })
  });
});