define('database-dashboard/models/database-lag', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * DatabaseLag structure. Its content is a JSON:
   *
   * {
   *   cluster: string,
   *   members: [
   *     {
   *       name: string,
   *       lag: float,
   *     },
   *   ],
   * }
   *
   */
  var DatabaseLag = Ember.Object.extend({});

  DatabaseLag.reopenClass({
    get: function get(dbId) {
      var url = window.EmberENV.apiBaseURI + '/databases/' + dbId + '/lag';
      return Ember.$.ajax({ url: url });
    }
  });

  exports.default = DatabaseLag;
});