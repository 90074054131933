define("database-dashboard/components/type-stats-table", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TypeStatsTableComponent = Ember.Component.extend({
    tagName: "table",
    classNames: ["bordered"],

    // Injected
    db: null,
    dbMetrics: null
  });

  exports.default = TypeStatsTableComponent;
});