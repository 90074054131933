define('database-dashboard/initializers/notificate', ['exports', 'clipboard'], function (exports, _clipboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DURATION = {
    'SHORT': 5000,
    'DEFAULT': 10000,
    'LONG': 30000
  };

  exports.default = {
    name: 'notifier-instance',
    initialize: function initialize(application) {
      var notifier = Ember.Object.create({
        notify: function () {
          var showing = 0;
          var queue = [];

          return function (text) {
            var _this = this;

            var duration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DURATION.DEFAULT;

            if (text) {
              if (typeof duration === 'string') {
                duration = DURATION[duration.toUpperCase()];
              }
              queue.push({ text: text, duration: duration });
            }
            // Limit to 3 toast at a time
            if (showing < 3) {
              showing++;
              var item = queue.shift();
              window.Materialize.toast(item.text, item.duration, '', function () {
                showing--;
                if (queue.length > 0) {
                  _this.notify();
                }
              });

              // Currently we initialize clipboardjs here which is not ideal
              if (item.text.indexOf('data-clipboard-text') !== -1) {
                new _clipboard.default('.do-copy').on('success', function () {
                  _this.notify('Copied!');
                }).on('error', function () {
                  _this.notify('If your are using Safari please double click password then press Ctrl+C to copy.');
                });
                // this.get('clipboard').destroy()
              }
            }
          };
        }()
      });

      application.register('notifier:current', notifier, { instantiate: false, singleton: true });

      application.inject('controller', 'notifier', 'notifier:current');
      application.inject('route', 'notifier', 'notifier:current');
      application.inject('component', 'notifier', 'notifier:current');
    }
  };
});