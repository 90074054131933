define('database-dashboard/models/plan', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    backup: _emberData.default.attr('boolean'),
    memory: _emberData.default.attr('number'),
    disk: _emberData.default.attr('number'), // In MB

    // SAND cluster specific
    gatewayNodes: _emberData.default.attr('number'),
    esMasterDataNodes: _emberData.default.attr('number'),
    mysqlNodes: _emberData.default.attr('number'),
    postgresqlNodes: _emberData.default.attr('number'),
    redisNodes: _emberData.default.attr('number'),

    // MongoDB specific
    mongoDataNode: _emberData.default.attr('number'),
    mongoArbiterNode: _emberData.default.attr('number'),
    mongoBackupNode: _emberData.default.attr('number'),

    diskBytes: Ember.computed('disk', function () {
      return this.get('disk') * 1024 * 1024;
    })
  });
});