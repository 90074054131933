define('database-dashboard/routes/dashboard/logs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Logs',

    model: function model() {
      var db = this.modelFor('dashboard').db;

      return Ember.RSVP.hash({
        db: db
      });
    },


    actions: {
      didTransition: function didTransition() {
        this.send('updateSelectedTab', 'logs');
      }
    }
  });
});