define('database-dashboard/routes/dashboard/advanced', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Advanced',

    model: function model() {
      var db = this.modelFor('dashboard').db;
      var dbId = db.get('id');
      var features = this.store.query('databaseFeature', { dbId: dbId });

      return Ember.RSVP.hash({
        db: db,
        data: {
          features: features
        }
      });
    },


    actions: {
      didTransition: function didTransition() {
        this.send('updateSelectedTab', 'advanced');
      }
    }
  });
});