define('database-dashboard/components/advanced-mongo', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AdvancedMongoComponent = Ember.Component.extend({
    confirmationModal: Ember.inject.service('confirmation-modal'),
    // Injected
    db: null,

    // Internal
    errors: null,
    isActivating: null,

    actions: {
      activateOplog: function activateOplog() {
        this.send('activateFeature', {
          feature: 'oplog',
          okMsg: 'Oplog activated'
        });
      },
      activateFeature: function activateFeature(opts) {
        var _this = this;

        var feature = opts.feature;
        this.set('isActivating', true);
        this.get('db').addFeature(feature).then(function (data) {
          if (data.message) {
            _this.get('notifier').notify(data.message);
          } else {
            _this.get('notifier').notify(opts.okMsg);
          }
        }, function (errors) {
          if (errors.error) {
            _this.get('notifier').notify('Failed to activate ' + feature + ', ' + errors.error);
          }
          _this.set('errors', errors);
        }).finally(function () {
          _this.set('isActivating', null);
        });
      },
      toggleActivateOplogConfirmation: function toggleActivateOplogConfirmation() {
        var _this2 = this;

        this.get('confirmationModal').display({
          okLabel: 'Create',
          action: function action() {
            _this2.send('activateOplog');
          },
          prompt: 'Do you really want to activate Oplog?'
        });
      }
    }
  });

  exports.default = AdvancedMongoComponent;
});