define('database-dashboard/components/db-metrics', ['exports', 'database-dashboard/helpers/bytes', 'database-dashboard/models/instance', 'database-dashboard/models/database'], function (exports, _bytes, _instance, _database) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    cont: null,
    tooltipPos: null,
    tooltipUpdate: null,
    metricsTime: null,
    timezone: null,
    cpuAxis: null,
    memAxis: null,
    swapAxis: null,
    showGraphs: true,
    showMore: false,
    icon: "visibility_off",

    cpuURLS: null,
    cpuTypes: ["cpu"],

    memoryURLS: null,
    memoryTypes: ["memory", "swap"],
    memoryColors: {
      "memory": "#03A9F4",
      "swap": "#DB909A"
    },

    diskUsedAxis: null,
    diskUsedURLs: null,
    diskUsedTypes: ["disk usage"], // Is displayed in the tooltip

    diskioUsedAxis: null,
    diskioUsedURLs: null,
    diskioUsedTypes: ["reads", "writes"], // Is displayed in the tooltip
    diskioColors: {
      "writes": "#03A9F4", // light blue
      "reads": "#f7ae04" // yellow-orange
    },

    database: null,
    databasePlan: null,
    instance: null,
    initDone: false,

    didInsertElement: function didInsertElement() {
      this.initGraph();
    },
    metricsTimeObserver: Ember.observer('metricsTime', function () {
      this.initGraph();
    }),
    dbObserver: Ember.observer('database', function () {
      this.initGraph();
    }),

    isDBNode: Ember.computed("instance.type", function () {
      return this.get("instance.type") == _instance.instanceTypes.DB_NODE;
    }),

    areDiskMetricsEnabled: Ember.computed("database.flags", function () {
      return !this.get('database.flags').contains(_database.disableDiskMetricsGraphFlag);
    }),

    initGraph: function initGraph() {
      var _this = this;

      var xAxis = {
        padding: 0,
        tick: {
          values: []
        }
      };
      var cpuAxis = {
        x: xAxis,
        y: {
          min: 0,
          max: 100,
          width: 40,
          padding: {
            top: 0,
            bottom: 0
          },
          tick: {
            count: 3,
            format: function format(d) {
              return Math.floor(100 * d) / 100 + "%";
            }
          }
        }
      };
      var memAxis = {
        x: xAxis,
        y: {
          width: 40,
          padding: {
            top: 0,
            bottom: 0
          },
          min: 0,
          max: 1,
          tick: {
            count: 3,
            format: _bytes.default
          }
        }
      };

      var diskUsedAxis = {
        x: xAxis,
        y: {
          width: 40,
          padding: {
            top: 0,
            bottom: 0
          },
          min: 0,
          max: 1,
          tick: {
            count: 3,
            format: _bytes.default
          }
        }
      };
      var diskioUsedAxis = {
        x: xAxis,
        y: {
          width: 40,
          padding: {
            top: 0,
            bottom: 0
          },
          min: 0,
          max: 1,
          tick: {
            count: 3,
            format: function format(val) {
              // This is called for every point in the chart
              return Math.floor(100 * val) / 100 + " io/s";
            }
          }
        }
      };

      this.get("database.currentPlan").then(function (plan) {
        _this.set("databasePlan", plan);
      });

      var baseURL = window.EmberENV.apiBaseURI + "/databases/" + this.get("database.id") + "/instances/" + this.get("instance.id") + "/metrics";
      this.setProperties({
        cpuAxis: cpuAxis,
        memAxis: memAxis,
        memoryURLS: [baseURL + "/memory?since=" + this.get('metricsTime'), baseURL + "/swap?since=" + this.get('metricsTime')],
        cpuURLS: [baseURL + "/cpu?since=" + this.get('metricsTime')],
        diskUsedAxis: diskUsedAxis,
        diskUsedURLs: [baseURL + "/disk?since=" + this.get('metricsTime')],
        diskioUsedAxis: diskioUsedAxis,
        diskioUsedURLs: [baseURL + "/diskio?since=" + this.get('metricsTime'),
        // We currently need to call this twice, even though it's the same endpoint to get the reads and the writes.
        // We will fix this in the upcoming release of the new DB dashboard.
        baseURL + "/diskio?since=" + this.get('metricsTime')]
      });
      return this.set("initDone", true);
    },

    setProperties: function setProperties(properties) {
      var key, results, value;
      results = [];
      for (key in properties) {
        value = properties[key];
        results.push(this.set(key, value));
      }
      return results;
    }
  });
});