define('database-dashboard/components/sc-md-switch', ['exports', 'database-dashboard/components/md-switch'], function (exports, _mdSwitch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ScMdSwitchComponent = _mdSwitch.default.extend({
    didInsertElement: function didInsertElement() {
      this.setTooltips();
    },
    setTooltips: function setTooltips() {
      var selector = "#" + this.get('elementId') + " .tooltipped";
      Ember.$(selector).tooltip();
    },


    errorWatcher: Ember.observer('error', function () {
      this.setTooltips();
    })
  });

  exports.default = ScMdSwitchComponent;
});