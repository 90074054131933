define('database-dashboard/components/confirmation-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // injected
    context: null,

    actions: {
      confirmAction: function confirmAction() {
        this.set('errors', null);
        var ctx = this.get('context');
        if (ctx.validationText && ctx.validationText != this.get('validationText')) {
          this.set('errors', ['validation text should match "' + ctx.validationText + '"']);
          return;
        }
        if (ctx.action) {
          ctx.action();
        }
        Ember.$("#confirmationModal").modal('close');
        this.set('validationText', null);
      }
    }
  });
});