define('database-dashboard/routes/authenticated', ['exports', 'database-dashboard/routes/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    beforeModel: function beforeModel(transition) {
      if (!this.get('session.authenticated')) {
        this.get('session').clear();
        this.redirectToLost(transition);
      }
    },
    redirectToLost: function redirectToLost() {
      this.transitionTo('you-are-lost');
    },


    actions: {
      // recover from any error that may happen during the transition to this route
      error: function error(reason, transition) {
        // if the HTTP status is 401 (unauthorised), redirect to the login page
        if (reason.status === 401) {
          this.redirectToLost(transition);
          return false;
        } else {
          console.log('unknown problem see in AuthenticatedRoute');
          console.log("transition", transition);
          console.trace(reason);
          return true;
        }
      }
    }
  });
});