define('database-dashboard/components/overview-right-cluster-topology', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OverviewRightClusterTopologyComponent = Ember.Component.extend({
    // Injected
    tagName: 'tr',
    db: null,
    dbLag: null,
    instance: null,

    postgreSQLInstanceLag: Ember.computed('instance', 'dbLag', function () {
      var instanceLag = this.get('instanceLag');
      if (instanceLag.length == 0) return 'n/a';

      return instanceLag[0]['lag_in_mega_bytes'] + ' MB';
    }),

    mySQLInstanceLag: Ember.computed('instance', 'dbLag', function () {
      var instanceLag = this.get('instanceLag');
      if (instanceLag.length == 0) return 'n/a';

      var lag = instanceLag[0]['lag_in_time'];
      if (lag == null) return '0';
      return lag;
    }),

    instanceLag: Ember.computed('instance', 'dbLag', function () {
      var dbLag = this.get('dbLag');
      if (dbLag == null || dbLag['members'] == null) return '';

      return dbLag['members'].filterBy('name', this.get('instance.id'));
    })
  });

  exports.default = OverviewRightClusterTopologyComponent;
});