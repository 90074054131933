define('database-dashboard/routes/dashboard/metrics', ['exports', 'database-dashboard/models/database-metrics'], function (exports, _databaseMetrics) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Metrics',

    model: function model() {
      var db = this.modelFor('dashboard').db;

      return Ember.RSVP.hash({
        db: db,
        metrics: _databaseMetrics.default.get(db.get('id'))
      });
    },


    actions: {
      didTransition: function didTransition() {
        this.send('updateSelectedTab', 'metrics');
      }
    }
  });
});